import React, {useRef, useState} from 'react';
import {
  Button,
  ModalContent,
  ModalBody,
  useColorMode,
  Modal,
  ModalOverlay,
  Flex,
  Box,
  Heading,
  ModalCloseButton,
  Text,
  useTheme,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import IntegrationSettings from '../integrations/IntegrationSettings';
import {useDispatch, useSelector} from 'react-redux';
import {convertIntegrationTypeToDisplay, fetchCreateIntegration, fetchRemoveIntegration, fetchUpdateIntegration, selectIntegrationSettingsView} from '../integrations/IntegrationsSlice';
import IntegrationReadme from '../integrations/IntegrationReadme';
import {getIntegrationReadme} from '../integrations/constants';

// TODO: REMEMBER ANALYTICS FOR CREATING AN INTEGRATION

const ModalCreateIntegration = ({
  close,
  data = {},
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const {id, type = 'new_relic'} = data;
  const settingsView = useSelector(state => selectIntegrationSettingsView(state, id, type));
  const defaultSettings = settingsView.reduce((acc, setting) => {
    acc[setting.id] = setting.value;
    return acc;
  }, {});
  const [settings, setSettings] = useState(defaultSettings);
  const [pending, setPending] = useState(false);
  const [removePending, setRemovePending] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleOnSubmit = async(e) => {
    setError('');
    setSuccess('');
    setPending(true);
    let data;
    if (!id) {
      data = await dispatch(fetchCreateIntegration({type, settings: settings}));
    } else {
      data = await dispatch(fetchUpdateIntegration({id, type, settings: settings}));
    }
    setPending(false);
    if (!data.error) {
      if (!id) close();
      else setSuccess('updated!');
    } else {
      setError(data.error.message);
    }
  };

  const handleRemove = async(e) => {
    setError('');
    setSuccess('');
    setRemovePending(true);
    const data = await dispatch(fetchRemoveIntegration(id));
    setRemovePending(false);
    if (!data.error) close();
    else setError(data.error.message);
  };

  const readme = getIntegrationReadme(type);

  return (
    <Modal
      data-testid='create-integration-modal'
      variant={colorMode}
      isOpen={true}
      onClose={close}
      isCentered
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent p={0} w='100vw' maxWidth={'52rem'}
        h={'38rem'} maxHeight='80vh'>
        <ModalCloseButton top={'1rem'} right={'1rem'}/>
        <ModalBody p={0} height='100%' width='100%' display='flex'>
          <Flex direction='column' w='100%'>
            <Box px={4} pt={4} pb={4} borderBottom='1px solid rgba(255, 255, 255, 0.05)' overflow='auto' flex='0 0 auto'><Heading size='md'>{`${!id ? 'New ' : ''} Integration - ${type && convertIntegrationTypeToDisplay(type)}`}</Heading></Box>
            <Flex h='100%'>
              <Flex direction='column' flex='0 0 65%'>
                <IntegrationReadme
                  img={readme.img}
                  title={readme.title}
                  body={readme.body}
                />
              </Flex>
              <Flex direction='column' justify='space-between' borderLeft='1px solid rgba(255, 255, 255, 0.05)' h='100%' flex='1 1 auto'>
                <Box flex='1 1 100%' p={4}>
                  <IntegrationSettings
                    key={'new_relic'}
                    view={id}
                    settings={settings}
                    setSettings={setSettings}
                  />
                </Box>
                <Flex flex='0 0 auto' direction='column' px={4} py={4} borderTop='1px solid rgba(255, 255, 255, 0.05)' justify='space-between'>
                  {success && <Text pt={3} color={colors.success[500]}>{success}</Text>}
                  {error && <Text pt={3} color={colors.error[500]}>{error}</Text>}
                  <Button
                    mt={2}
                    flex='0 0 auto'
                    w="100%"
                    variant="primary"
                    onClick={e => handleOnSubmit(e)}
                    isLoading={pending}
                    disabled={pending || removePending}
                  >
                    {id ? 'Update' : 'Create'} Integration
                  </Button>
                  { id &&
                    <Button
                      mt={2}
                      flex='0 0 auto'
                      w="100%"
                      bgColor={`${colorMode}.grey.500`}
                      onClick={e => handleRemove(e)}
                      color={`${colorMode}.red.200`}
                      isLoading={removePending}
                      disabled={pending || removePending}
                    >
                      Remove Integration
                    </Button>
                  }
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ModalCreateIntegration.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalCreateIntegration;
