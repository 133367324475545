import React from 'react';
import {useSelector} from 'react-redux';
import {
  selectSelectedWorkspaceName,
  selectSelectedWorkspaceFeatureFlag,
} from '../app/WorkspacesSlice';
import {selectLairViewName, selectLairIsProd} from '../features/lairs/LairsSlice';
import Toolbar from './Toolbar';
import NextRouter from 'next/router';
import {FEATURES} from '../features/flags';
import commands from '../features/hotkeys/commands';

const AppToolbar = ({view, disabled}) => {
  const workspaceName = useSelector(selectSelectedWorkspaceName);
  const lairName = useSelector(selectLairViewName);
  const lairTabEndpoint = name => {
    NextRouter.push(`/workspaces/${workspaceName}/lairs/${lairName}/${name}`);
  };
  // TODO read other flags from redux
  const alertsFlag = useSelector(state => selectSelectedWorkspaceFeatureFlag(state, 'alerts'));
  const isProd = useSelector(selectLairIsProd);
  const {
    workspaceHome: wh,
    lairDevelop: le,
    lairReadme: lr,
    lairDeploy: ld,
    lairAlerts: la,
    lairLogs: lo,
    lairPublish: lp,
    lairEndpoints: lu,
  } = commands;
  const top = {
    title: 'Home',
    icon: 'home',
    onClick: () => NextRouter.push(`/workspaces/${workspaceName}`),
    command: wh,
  };
  const main = [{
    selected: view === 'develop',
    title: 'Develop',
    icon: 'edit',
    onClick: () => lairTabEndpoint(`develop${isProd ? '/prod' : ''}`),
    command: le,
  }, {
    selected: view === 'readme',
    title: 'Readme',
    icon: 'readme',
    onClick: () => lairTabEndpoint('readme'),
    command: lr,
  }, {
    selected: view === 'deploy',
    title: 'Deploy',
    icon: 'deploy',
    onClick: () => lairTabEndpoint('deploy'),
    command: ld,
  }, alertsFlag && {
    selected: view === 'alerts',
    title: 'Alerts',
    icon: 'alert',
    onClick: () => lairTabEndpoint('alerts'),
    command: la,
  }, {
    selected: view === 'logs',
    title: 'Logs',
    icon: 'log',
    onClick: () => lairTabEndpoint('logs'),
    command: lo,
  }, {
    selected: view === 'publish',
    title: 'Publish',
    icon: 'publish',
    onClick: () => lairTabEndpoint('publish'),
    command: lp,
  }];

  if (FEATURES.endpoints) {
    main.push({
      selected: view === 'endpoints',
      title: 'Endpoints',
      icon: 'endpoints',
      onClick: () => lairTabEndpoint('endpoints'),
      command: lu,
    });
  }
  // const bottom = {
  //   icon: avatar
  //     ? <UserImage
  //       img={avatar}
  //       name={firstName}
  //       readOnly={true}
  //       size={'sm'} />
  //     : 'logo',
  // };
  return <>
    <Toolbar top={top} main={main} disabled={disabled}/>
  </>;
};

export default AppToolbar;
