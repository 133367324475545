import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {saveAllUnsavedFiles, selectIsAnyTabUnsaved} from '../features/editor/EditorSlice';
import {isElectron} from '../utils/helpers';
import {selectAppReady} from '../app/store';

const useAnyUnsavedFiles = () => {
  const dispatch = useDispatch();
  const isAnyTabUnsaved = useSelector(selectIsAnyTabUnsaved);
  const appReady = useSelector(selectAppReady);

  useEffect(() => {
    if (!appReady) return;
    const handleSaveAllFiles = async(event, data) => {
      if (data.type === 'SAVE_ALL_UNSAVED_FILES') {
        await dispatch(saveAllUnsavedFiles());
      }
    };

    let removeListener;
    if (isElectron) {
      removeListener = window.electron.message.on('message', handleSaveAllFiles);
      window.electron.message.send('message', {
        type: 'HAS_UNSAVED_FILES',
        value: isAnyTabUnsaved,
      });
    }
    return () => {
      if (isElectron) {
        removeListener();
      }
    };
  }, [appReady, isAnyTabUnsaved]);
};

export default useAnyUnsavedFiles;
