import {Flex, Image, Heading, Box, useColorMode, useTheme} from '@chakra-ui/react';

const IntegrationReadme = ({
  img,
  title,
  body,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  return (
    <Flex direction='column'>
      <Image src={img} w='100%'/>
      <Heading size='sm' mt={4} px={3}>{title}</Heading>
      <Box css={{
        a: {color: colors[colorMode].primary[500]},
      }} color='grey' mt={4} px={3}>
        {body}
      </Box>
    </Flex>
  );
};

export default IntegrationReadme;
