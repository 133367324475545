import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Box, Flex, useColorMode} from '@chakra-ui/react';
import {bodyFontSize} from '../theme';
import {useDispatch, useSelector} from 'react-redux';
import {selectPanels, setPanels} from '../features/layout/PanelBottomSlice';

const AppBody = ({
  width = 55,
  height = 25,
  left,
  leftWidth = null, // fixed width
  bottom,
  bottomHeader,
  main,
  mainHeader,
  bottomToolbar,
  handleOnResize = () => {},
}) => {
  const panels = JSON.parse(useSelector(selectPanels));
  const {colorMode} = useColorMode();
  const dispatch = useDispatch();
  const dragStart = useRef(null);

  function handleMouseDownBottom(e) {
    dragStart.current = e.pageY;
    document.addEventListener('mouseup', handleMouseUpBottom);
    document.addEventListener('mousemove', handleMouseMoveBottom);
  }
  function handleMouseDownLeft(e) {
    dragStart.current = e.pageX;
    document.addEventListener('mouseup', handleMouseUpLeft);
    document.addEventListener('mousemove', handleMouseMoveLeft);
  }
  function handleMouseUpBottom(e) {
    const panelState = panels.bottom;
    const dragEnd = e.pageY;
    document.removeEventListener('mouseup', handleMouseUpBottom);
    document.removeEventListener('mousemove', handleMouseMoveBottom);
    const delta = (dragEnd - dragStart.current) / bodyFontSize;
    let newHeight;
    if (delta < 0) { // increase size with max limit
      newHeight = Math.min(height, panelState.height + Math.abs(delta));
    } else {
      newHeight = Math.max(panelState.minHeight, panelState.height - Math.abs(delta));
    }
    dispatch(setPanels({
      ...panels,
      bottom: {
        ...panels.bottom,
        height: newHeight,
      },
    }));
  }
  function handleMouseUpLeft(e) {
    const panelState = panels.left;
    const dragEnd = e.pageX;
    document.removeEventListener('mouseup', handleMouseUpLeft);
    document.removeEventListener('mousemove', handleMouseMoveLeft);
    const delta = (dragEnd - dragStart.current) / bodyFontSize;
    let newWidth;
    if (delta > 0) { // increase size with max limit
      newWidth = Math.min(width, panelState.width + Math.abs(delta));
    } else {
      newWidth = Math.max(panelState.minWidth, panelState.width - Math.abs(delta));
    }
    dispatch(setPanels({
      ...panels,
      left: {
        ...panels.left,
        width: newWidth,
      },
    }));
  }
  function handleMouseMoveBottom(e) {
    handleOnResize();
    const panelState = panels.bottom;
    const dragEnd = e.pageY;
    const delta = (dragEnd - dragStart.current) / bodyFontSize;
    let newHeight;
    if (delta < 0) { // increase size with max limit
      newHeight = Math.min(height, panelState.height + Math.abs(delta));
    } else {
      newHeight = Math.max(panelState.minHeight, panelState.height - Math.abs(delta));
    }
    const panel = document.getElementById('app_bottom_panel');
    if (panel) {
      panel.style.flex = `0 0 ${newHeight}rem`;
      panel.style.height = `${newHeight}rem`;
    }
  }
  function handleMouseMoveLeft(e) {
    handleOnResize();
    const panelState = panels.left;
    const dragEnd = e.pageX;
    const delta = (dragEnd - dragStart.current) / bodyFontSize;
    let newWidth;
    if (delta > 0) { // increase size with max limit
      newWidth = Math.min(width, panelState.width + Math.abs(delta));
    } else {
      newWidth = Math.max(panelState.minWidth, panelState.width - Math.abs(delta));
    }
    const panel = document.getElementById('app_left_panel');
    if (panel) {
      panel.style.flex = `0 0 ${newWidth}rem`;
      panel.style.width = `${newWidth}rem`;
    }
  }

  useEffect(() => {
    const bottomHeading = document.getElementById('app_bottom_header');
    if (bottom && bottomHeading) {
      if (bottomHeading) {
        bottomHeading.addEventListener('mousedown', handleMouseDownBottom);
      }
    }
    const leftHandle = document.getElementById('app_left_handle');
    if (left && !leftWidth && leftHandle) {
      if (leftHandle) {
        leftHandle.addEventListener('mousedown', handleMouseDownLeft);
      }
    }

    return () => {
      if (bottom && bottomHeading) {
        bottomHeading.removeEventListener('mousedown', handleMouseDownBottom);
      }
      if (left && !leftWidth && leftHandle) {
        leftHandle.removeEventListener('mousedown', handleMouseDownLeft);
      }
    };
  });

  useEffect(() => {
    if (handleOnResize) handleOnResize();
  }, [width, height]);

  return (
    <Flex
      position="relative"
      width={'100vw'}
      height={'100vh'}
      background={'gray.600'}
    >
      <Flex
        id="app_left_panel"
        background={`${colorMode}.bg.600`}
        width={`${leftWidth || panels.left.width}rem`}
        flex={`0 0 ${leftWidth || panels.left.width}rem`}
        borderRight="1px"
        borderColor={`${colorMode}.grey.500`}
        overflow={'hidden'}
        minW="3.5rem"
        position='relative'
      >
        <Box
          flex="1 1 auto"
          width="100%"
        >
          {left}
        </Box>
        {!leftWidth && <Box
          id="app_left_handle"
          cursor={leftWidth ? 'pointer' : 'col-resize'}
          background="transparent"
          flex="0 0 .3rem"
          width=".3rem"
          zIndex="1"
          position="absolute"
          top="0"
          height="100%"
          right="0"
        >
        </Box>}
      </Flex>
      <Flex
        direction="column"
        flex="1 1 auto"
        minWidth="0"
      >
        <Flex
          background={`${colorMode}.bg.800`}
          flex="1 1 auto"
          direction='column'
          minHeight="0"
        >
          {mainHeader && <Box background={`${colorMode}.bg.600`} flex='0 0 2rem'>{mainHeader}</Box>}
          {main}
        </Flex>
        {bottom &&
        <Flex
          id="app_bottom_panel"
          background={`${colorMode}.bg.700`}
          width="100%"
          height={`${panels.bottom.height}rem`}
          direction='column'
          zIndex={1}
        >
          {bottomHeader &&
          <Box
            id="app_bottom_header"
            background={`${colorMode}.bg.400`}
            cursor="row-resize"
            borderTop="1px"
            borderBottom="1px"
            borderColor={`${colorMode}.grey.500`}
          >
            {bottomHeader}
          </Box>}
          {bottom}
        </Flex>
        }
        {bottomToolbar &&
          <Box>{bottomToolbar}</Box>
        }
      </Flex>
    </Flex>
  );
};

AppBody.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  left: PropTypes.node,
  leftWidth: PropTypes.number, // fixed width in rem
  bottom: PropTypes.node,
  bottomHeader: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    PropTypes.node,
  ]),
  main: PropTypes.node,
  handleOnResize: PropTypes.func,
};

export default AppBody;
