import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ModalCreateLair from './ModalCreateLair';
import ModalRemoveTrigger from './ModalRemoveTrigger';
import ModalRemoveIntegration from './ModalRemoveIntegration';
import ModalDeleteFile from './ModalDeleteFile';
import {
  closeModal,
  hideModal,
  selectCurrentModal,
} from './ModalSlice';
import ModalFileSave from './ModalFileSave';
import ModalDeployLair from './ModalDeployLair';
import ModalLeaveWorkspace from './ModalLeaveWorkspace';
import ModalBilling from './ModalBilling';
import ModalBoost from './ModalBoost';
import ModalGeneralAskPrompt from './ModalGeneralAskPrompt';
import ModalCreateIntegration from './ModalCreateIntegration';
import ModalChooseIntegration from './ModalChooseIntegration';

const Modal = (props) => {
  switch (props.which) {
  case 'create_lair':
    return (
      <ModalCreateLair {...props}/>
    );
  case 'remove_trigger':
    return (
      <ModalRemoveTrigger {...props} />
    );
  case 'remove_integration':
    return (
      <ModalRemoveIntegration {...props} />
    );
  case 'delete_file':
    return (
      <ModalDeleteFile {...props} />
    );
  case 'file_save':
    return (
      <ModalFileSave {...props} />
    );
  case 'deploy_lair':
    return (
      <ModalDeployLair {...props} />
    );
  case 'leave_workspace':
    return (
      <ModalLeaveWorkspace {...props} />
    );
  case 'update_billing':
    return (
      <ModalBilling {...props} />
    );
  case 'boost_workspace':
    return (
      <ModalBoost {...props} />
    );
  case 'general_ask_prompt':
    return (
      <ModalGeneralAskPrompt {...props} />
    );
  case 'create_integration':
    return (
      <ModalCreateIntegration {...props} />
    );
  case 'choose_integration':
    return (
      <ModalChooseIntegration {...props} />
    );
  default:
    return (
      <></>
    );
  }
};

const ModalView = () => {
  let modal = useSelector(state => {
    const m = selectCurrentModal(state) || '{}';
    return JSON.stringify(m);
  });
  modal = JSON.parse(modal);
  const dispatch = useDispatch();
  if (!modal.show) return null;
  return (
    <Modal
      key={modal.which}
      which={modal.which}
      data={modal.data}
      hide={() => dispatch(hideModal({which: modal.which}))}
      close={() => dispatch(closeModal({which: modal.which}))}
    />
  );
};

export default ModalView;
