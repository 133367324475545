import AppLayout from './AppLayout';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {fetchTriggers} from '../features/triggers/TriggersSlice';
import Sync from '../features/sync/Sync';
import UpdateVersion from '../features/desktop/UpdateVersion';
import useAppClose from '../lib/useAppClose';
import useAnyUnsavedFiles from '../lib/useAnyUnsavedFiles';
import useFilesChanged from '../lib/useFilesChanged';
import {useDefaultPanels} from '../lib/useDefaultPanels';
import AppLoading from './AppLoading';
import {PLATFORM, IS_WEB_PROD} from '../app/constants';
import {
  Flex,
} from '@chakra-ui/react';
import router from 'next/router';
import {selectSelectedWorkspaceName} from '../app/WorkspacesSlice';
import {isElectron} from '../utils/helpers';
import HotKeysHelp from './HotKeysHelp';
import useAppHotkeys from '../lib/useAppHotkeys';

const App = ({
  isAppReadyAndIsLoggedIn,
  children,
  isIndexPage,
  view,
}) => {
  const workspace = useSelector(state => state.workspaces.selected);
  const workspaceName = useSelector(selectSelectedWorkspaceName);
  const triggersInit = useSelector(state => state.triggers.status === 'init');
  const version = useSelector(state => state.desktop.currentVersion);
  const showHotkeys = useSelector(state => state.hotkeys.help);
  const tokenStatus = useSelector(state => state.user.status_access_token);
  const dispatch = useDispatch();
  useAppClose();
  useAnyUnsavedFiles();
  useFilesChanged();
  useDefaultPanels();
  const hotkeys = useAppHotkeys({view});

  const loading = !isAppReadyAndIsLoggedIn || !workspace;
  const shouldFetchTriggers = triggersInit && isAppReadyAndIsLoggedIn && tokenStatus === 'idle';
  useEffect(() => {
    if (shouldFetchTriggers) dispatch(fetchTriggers());
  }, [shouldFetchTriggers]);

  useEffect(() => {
    if (isIndexPage && isAppReadyAndIsLoggedIn && workspaceName) {
      router.push(`/workspaces/${workspaceName}/lairs/`);
    }
  }, [isIndexPage, isAppReadyAndIsLoggedIn, workspaceName]);

  return (
    loading ? <AppLayout><AppLoading /></AppLayout>
      : <>
        {isElectron && <UpdateVersion />}
        <Sync context="app"/>
        {(!IS_WEB_PROD && version) && <Flex
          id="app_version"
          position="absolute"
          bottom=".15rem"
          right={PLATFORM === 'win' ? '1.85rem' : '.35rem'}
          zIndex="100000"
          userSelect="none"
        >
          {version}
        </Flex>}
        <AppLayout isAppReadyAndIsLoggedIn={isAppReadyAndIsLoggedIn}>
          {children}
        </AppLayout>
        {showHotkeys && <HotKeysHelp open={showHotkeys} hotkeys={hotkeys} />}
      </>
  );
};

export default App;
