import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {saveAllUnsavedFiles, selectIsAnyTabUnsaved, selecUnsavedTabsLength} from '../features/editor/EditorSlice';
import {isElectron} from '../utils/helpers';
import {selectAppReady} from '../app/store';
import {debugLog} from '../../helpers';

const useAppClose = () => {
  const dispatch = useDispatch();
  const isAnyTabUnsaved = useSelector(selectIsAnyTabUnsaved);
  const unsavedTabLength = useSelector(selecUnsavedTabsLength);
  const appReady = useSelector(selectAppReady);

  useEffect(() => {
    if (appReady && isElectron) {
      window.electron.message.invoke('message', {
        type: 'SET_CAN_APP_CLOSE',
        value: false,
      });
    }
    return () => {
      if (appReady && isElectron) {
        window.electron.message.invoke('message', {
          type: 'SET_CAN_APP_CLOSE',
          value: true,
        });
      }
    };
  }, [appReady]);

  useEffect(() => {
    if (!appReady) return;
    const renderWebAppCloseConfirm = e => {
      e = e || window.event;

      // For IE and Firefox prior to version 4
      if (isAnyTabUnsaved) {
        if (e) {
          e.returnValue = 'Sure?';
        }
        // For Safari
        return 'Sure?';
      }
    };

    const handleAppCloseCheck = async(event, data) => {
      if (data.type === 'APP_CLOSE_CHECK') {
        const response = await window.electron.message.invoke('message', {
          type: 'APP_CLOSE',
          has_unsaved_changes: isAnyTabUnsaved,
          length: unsavedTabLength,
        });
        const {payload} = response;
        const choice = payload.response;
        if (choice === 0) {
          const data = await dispatch(saveAllUnsavedFiles());
          const success = data.reduce((acc, d) => acc && !d.error, true);
          if (success) {
            if (data.payload === 'install') {
              window.electron.message.send('message', {
                type: 'RESTART_AND_INSTALL',
              });
            } else {
              window.electron.message.send('message', {
                type: 'QUIT_APPLICATION',
              });
            }
          } else {
            debugLog('ERROR SAVE ALL');
            // TODO SAVE UX - UX FOR WHEN A FILE SAVE ERRORS?
          }
        }
      }
    };

    let appCloseRemoveListener;
    if (!isElectron) {
      window.addEventListener('beforeunload', renderWebAppCloseConfirm);
    } else {
      appCloseRemoveListener = window.electron.message.on('message', handleAppCloseCheck);
    }
    return () => {
      if (!isElectron) {
        window.removeEventListener('beforeunload', renderWebAppCloseConfirm);
      } else {
        appCloseRemoveListener();
      }
    };
  }, [isAnyTabUnsaved, unsavedTabLength]);
};

export default useAppClose;
