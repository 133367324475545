import {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  selectCurrentModal,
} from './ModalSlice';
import {
  Button,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorMode,
  Modal,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {useTheme} from '@emotion/react';

const ModalAskPrompt = () => {
  const modal = useSelector(selectCurrentModal) || {};
  const {data} = modal;
  const {
    options = [],
    cancel,
    cancelText = 'Cancel',
    header = 'Please choose an option to proceed',
  } = data;
  const initialFocusRef = useRef(null);
  const {colorMode} = useColorMode();
  const [error] = useState(null);
  const theme = useTheme();

  const handleCancel = () => {
    if (cancel) cancel();
  };

  return (
    <Modal
      data-testid='file-save-modal'
      variant={colorMode}
      isOpen={true}
      onClose={handleCancel}
      isCentered
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader userSelect="none">{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error &&
            <FormLabel userSelect="none" mt="2" color={`${colorMode}.red.500`}>{error}</FormLabel>
          }
        </ModalBody>
        <ModalFooter>
          <Flex direction='column' w='100%'>
            {
              options.map(option => {
                return (
                  <Button
                    key={option.text}
                    w="100%"
                    variant="primary"
                    onClick={option.onClick}
                    my={`${theme.space[3]}`}
                  >
                    {option.text}
                  </Button>
                );
              })
            }
            <Button
              w="100%"
              variant="primary"
              onClick={handleCancel}
              my={`${theme.space[3]}`}
            >
              {cancelText}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalAskPrompt.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalAskPrompt;
