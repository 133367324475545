import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useColorMode, Box, Flex, Heading, Text, Spinner} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IconNewRelic} from '../../components/Icons';
import IntegrationButton from '../integrations/IntegrationButton';
import {convertIntegrationTypeToDisplay, selectIntegrationsMetadata} from '../integrations/IntegrationsSlice';
import {showModal} from './ModalSlice';

const ModalChooseIntegration = ({
  close,
  data = {},
}) => {
  const {colorMode} = useColorMode();
  const inputRef = useRef(null);
  const integrations = JSON.parse(useSelector(state => selectIntegrationsMetadata(state, {stringify: true})));
  const integrationMetadataStatus = useSelector(state => state.integrations.statusMetadata);
  const dispatch = useDispatch();
  return (
    <Modal
      data-testid='choose-integration-modal'
      variant={colorMode}
      isOpen={true}
      onClose={close}
      isCentered
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent p={0} w='100vw' maxWidth={'52rem'}
        h={'38rem'} maxHeight='80vh'>
        <ModalCloseButton top={'1rem'} right={'1rem'}/>
        <ModalBody p={0} height='100%' width='100%' display='flex'>
          <Flex direction='column' w='100%'>
            <Box px={4} pt={4} pb={4} borderBottom='1px solid rgba(255, 255, 255, 0.05)' overflow='auto' flex='0 0 auto'><Heading size='md'>{'New Integration'}</Heading></Box>
            {integrationMetadataStatus === 'idle' &&
              <Flex wrap='wrap' p={4}>
                <Text w='100%' mb={4} color='grey'>Monitoring</Text>
                {integrations.map(type => {
                  return (
                    <IntegrationButton
                      onClick={e => {
                        close();
                        dispatch(showModal({
                          which: 'create_integration',
                          data: {type},
                        }));
                      }}
                      key={'choice-' + type}
                      name={convertIntegrationTypeToDisplay(type)}
                      icon={<IconNewRelic />}
                    />
                  );
                })}
              </Flex>
            }
            {integrationMetadataStatus === 'pending' &&
              <Flex w='100%' justify='center' p={4}>
                <Spinner />
              </Flex>
            }
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ModalChooseIntegration.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalChooseIntegration;
