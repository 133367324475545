import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  createDeployment,
  fetchDeleteDeployment,
  setLairView,
  selectLairView,
} from '../lairs/LairsSlice';
import ModalConfirmPure from './ModalConfirmPure';
import {useToast} from '@chakra-ui/react';

const ModalDeployLair = ({
  close,
  data,
}) => {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const lairView = useSelector(selectLairView);
  const toast = useToast();

  let handleDeploy = null;
  if (!data.isProd) {
    handleDeploy = async() => {
      setPending(true);
      dispatch(createDeployment(lairView, toast));
      close();
    };
  } else {
    handleDeploy = async() => {
      setPending(true);
      const data = await dispatch(fetchDeleteDeployment());
      if (!data.error) {
        setPending(false);
        dispatch(setLairView(lairView));
        setError(false);
        close();
      } else {
        setPending(false);
        setError(true);
      }
    };
  }
  return (
    <ModalConfirmPure
      close={close}
      handleConfirm={() => handleDeploy()}
      onClose={close}
      title={`Confirm ${data.isProd ? 'De-Deploy' : 'Deploy'}`}
      confirmText={data.isProd ? 'De-Deploy' : 'Deploy'}
      variant={data.isProd ? 'red' : 'primary'}
      pending={pending}
      error={error}
    />
  );
};

ModalDeployLair.propTypes = {
  data: PropTypes.shape({
    isProd: PropTypes.bool,
  }),
  close: PropTypes.func,
};

export default ModalDeployLair;
