import React from 'react';
import {
  useColorMode,
  useTheme,
  Flex,
  Text,
} from '@chakra-ui/react';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectSelectedWorkspaceId,
  selectSelectedWorkspacePropById,
  selectAllWorkspaces,
  setSelectedWorkspace,
} from '../app/WorkspacesSlice';
import {logout, selectUserProp} from '../features/user/UserSlice';
import {
  IconLogo,
  IconCheck,
  IconPlus,
  IconChevronDown,
} from './Icons';
import DropdownMenu from './DropdownMenu';
import UserImage from './UserImage';
import {useRouter} from 'next/router';
import commands from '../features/hotkeys/commands';
import {isElectron} from '../utils/helpers';

const ExplorerHeader = () => {
  const firstName = useSelector(state => selectUserProp(state, 'firstName'));
  const avatar = useSelector(state => selectUserProp(state, 'avatar'));
  const workspaceId = useSelector(selectSelectedWorkspaceId);
  const workspaceName = useSelector(state => selectSelectedWorkspacePropById(state, 'name'));

  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();

  const workspaceItems = useSelector(state => {
    const workspaces = selectAllWorkspaces(state);
    const items = workspaces.map((w, i) => {
      return {
        display: w.name,
        onClick: async() => {
          if (w.id !== workspaceId) {
            await dispatch(setSelectedWorkspace(w.id));
            router.push(`/workspaces/${w.name}/lairs`);
          }
        },
        actionIcon: (w.id === workspaceId)
          ? <IconCheck width='1.5rem' height='1.5rem' color={colors[colorMode].primary[500]}/>
          : null,
        hotkey: commands.openWorkspace(i + 1),
      };
    });
    return items;
  });
  workspaceItems.push({
    display: 'New Workspace',
    actionIcon: <IconPlus width='.65rem' height='.65rem' color={colors[colorMode].grey[200]}/>,
    color: colors[colorMode].grey[200],
    onClick: () => dispatch(setSelectedWorkspace(null)),
  });

  const menuSettings = {
    items: [
      {
        title: 'Workspaces',
        items: workspaceItems,
        iconSize: 'md',
        fontSize: 'sm',
      },
      {
        items: [
          {
            display: 'Settings',
            onClick: workspaceName
              ? () => router.push(`/workspaces/${workspaceName}/settings/general/`)
              : undefined,
            hotkey: commands.workspaceSettings,
          },
          {
            display: 'Sign Out',
            onClick: async() => {
              await dispatch(logout());
            },
            hotkey: commands.logout,
          },
        ],
      },
    ],
  };
  if (!isElectron) {
    menuSettings.items[1].items.splice(1, 0, {
      display: 'Download Desktop App',
      onClick: () => window.open('https://wayscript.com/downloads'),
    });
  }

  return (
    <Flex
      width='100%'
      p="3"
      borderBottom='1px'
      align="center"
      justify="space-between"
      height="10"
      borderColor={`${colorMode}.grey.500`}
      cursor="pointer">
      <DropdownMenu
        appendTo={typeof window !== 'undefined' ? document.body : undefined}
        style={{width: '100%'}}
        settings={menuSettings}>
        <Flex w="100%"
          align="center">
          {avatar
            ? <UserImage
              img={avatar}
              name={firstName}
              readOnly={true}
              size={'sm'} />
            : <IconLogo width="1.25rem" height="1.25rem" />
          }
          <Text userSelect="none" mx="3">{workspaceName || ''}</Text>
          <Flex
            align="center"
            cursor="pointer"
            sx={{
              '&:hover path': {
                fill: `${colorMode}.grey.300`,
              },
            }}
          >
            <IconChevronDown width=".6rem" height=".6rem" />
          </Flex>
        </Flex>
      </DropdownMenu>
    </Flex>
  );
};

export default ExplorerHeader;
