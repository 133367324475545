import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {fetchDeleteFiles, selectAllDeletableSelectedFilesNames, selectDeletableFiles, selectFilePropById} from '../files/FilesSlice';
import ModalConfirmPure from './ModalConfirmPure';
import {setFileWarningPreference} from '../preferences/PreferencesSlice';
import * as analytics from '../../utils/analytics';

const ModalDeleteFile = ({
  close,
  data,
}) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const selectedFilesNames = useSelector(selectAllDeletableSelectedFilesNames);
  const name = useSelector(state => selectFilePropById(state, data.id, 'name'));
  const deletableSelectedFileIDs = useSelector(selectDeletableFiles);
  const [preference, setPreference] = useState(false);

  const handleClick = async() => {
    if (data.isLair) {
      analytics.track(analytics.lairDeletedEvent);
    }
    setPending(true);
    setError(false);

    let IDsToDelete;
    if (data.shouldDeleteSelectedFiles) {
      IDsToDelete = deletableSelectedFileIDs;
    } else {
      IDsToDelete = [data.id];
    }
    if (preference) dispatch(setFileWarningPreference(false));
    try {
      const {error} = await dispatch(fetchDeleteFiles(IDsToDelete, data.isWorkspace, data.isLair));
      if (error) setError(error);
      setPending(false);
      if (!error) close();
    } catch (error) {
      setError(error);
    }
  };

  let fileNamesToDelete = [];
  if (data.shouldDeleteSelectedFiles) {
    fileNamesToDelete = selectedFilesNames;
  } else {
    fileNamesToDelete = [name];
  }

  return (
    <ModalConfirmPure
      close={close}
      handleConfirm={handleClick}
      error={error}
      pending={pending}
      bodyText={[
        `This will permanantly delete these ${data.isWorkspace ? 'workspace(s)' : (data.isLair ? 'lair(s)' : 'file(s)')} :`,
        ...fileNamesToDelete,
        '\n',
        'This action cannot be undone.',
      ]}
      title={`Delete ${data.isWorkspace ? 'Workspace' : (data.isLair ? 'Lair' : 'File')}?`}
      preference={!data.isWorkspace && !data.isLair && (() => setPreference(!preference))}
    />
  );
};

ModalDeleteFile.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalDeleteFile;
