import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {removeTrigger} from '../triggers/TriggersSlice';
import ModalConfirmPure from './ModalConfirmPure';

const ModalRemoveEvent = ({
  close,
  data,
}) => {
  const dispatch = useDispatch();

  return (
    <ModalConfirmPure
      close={close}
      handleConfirm={() => {
        dispatch(removeTrigger(data));
        close();
      }}
      onClose={close}
      bodyText={[
        'This action cannot be undone.',
      ]}
      title='Are you sure?'
    />
  );
};

ModalRemoveEvent.propTypes = {
  data: PropTypes.string,
  close: PropTypes.func,
};

export default ModalRemoveEvent;
