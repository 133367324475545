import React from 'react';
import {
  IconLogo,
  IconFilePy,
  IconClock,
  IconLair,
  IconDeploy,
  IconSlack,
  IconSql,
  IconHttp,
  IconNotifications,
  IconUser,
  IconKey,
  IconLog,
  IconUpload,
  IconHome,
  IconWrench,
  IconEdit,
  IconBlog,
  IconGlobe,
  IconLogic,
} from './Icons';

const Icon = ({
  defaultIcon,
  name,
  ...props
}) => {
  let icon;
  switch (name) {
  case 'logo':
    icon = <IconLogo {...props} />;
    break;
  case 'lair':
    icon = <IconLair {...props} />;
    break;
  case 'deploy':
    icon = <IconDeploy {...props} />;
    break;
  case 'prod':
    icon = <IconDeploy {...props} />;
    break;
  case 'clock':
    icon = <IconClock {...props} />;
    break;
  case 'slack':
    icon = <IconSlack {...props} />;
    break;
  case 'sql':
    icon = <IconSql {...props} />;
    break;
  case 'http':
    icon = <IconHttp {...props} />;
    break;
  case 'user':
    icon = <IconUser {...props} />;
    break;
  case 'alert':
    icon = <IconNotifications {...props} />;
    break;
  case 'secret':
    icon = <IconKey {...props} />;
    break;
  case 'log':
    icon = <IconLog {...props} />;
    break;
  case 'cron':
    icon = <IconClock {...props} />;
    break;
  case 'upload':
    icon = <IconUpload {...props} />;
    break;
  case 'home':
    icon = <IconHome {...props} />;
    break;
  case 'edit':
    icon = <IconEdit {...props} />;
    break;
  case 'readme':
  case 'blog':
    icon = <IconBlog {...props} />;
    break;
  case 'globe':
  case 'publish':
    icon = <IconGlobe {...props} />;
    break;
  case 'dev':
  case 'wrench':
    icon = <IconWrench {...props} />;
    break;
  case 'endpoints':
    icon = <IconLogic {...props} />;
    break;
  case 'py':
    icon = <IconFilePy {...props}/>;
    break;
  case 'python':
    icon = <IconFilePy {...props}/>;
    break;
  default:
    icon = defaultIcon || <IconFilePy {...props}/>;
  }
  return icon;
};

export default Icon;
