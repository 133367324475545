import {Flex, Heading, Button} from '@chakra-ui/react';

const IntegrationButton = ({
  onClick,
  name,
  icon,
}) => {
  return (
    <Button background='none' outline='1px solid grey'>
      <Flex onClick={onClick} align='center'>
        {icon}
        <Heading size='sm' ml={3}>{name}</Heading>
      </Flex>
    </Button>
  );
};

export default IntegrationButton;
