import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SELF_HOSTED} from '../../app/constants';
import {
  Button,
  FormLabel,
  Input,
  ModalContent,
  ModalBody,
  useColorMode,
  useTheme,
  Modal,
  ModalOverlay,
  Flex,
  Box,
  Heading,
  Spinner,
  Text,
  Image,
  Code,
} from '@chakra-ui/react';
import {fetchClonePublicLair, fetchCreateLair, fetchLairTemplates, fetchPublicLairData, openLair} from '../lairs/LairsSlice';
import {syncFiles} from '../files/FilesSlice';
import {selectSelectedWorkspaceId, selectSelectedWorkspaceName} from '../../app/WorkspacesSlice';
import {updateModalData} from './ModalSlice';
import PropTypes from 'prop-types';
import {debounce} from '../../utils/helpers';
import LairTemplate from '../files/LairTemplate';
import Markdown from '../../components/Markdown';
import {IconCaution} from '../../components/Icons';
import * as analytics from '../../utils/analytics';

const ModalCreateLair = ({
  close,
  data,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [templatesError, setTemplatesError] = useState(null);
  const [templatesData, setTemplatesData] = useState(null);
  const [pending, setPending] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('new_lair_selection');
  const [readmeContent, setReadmeContent] = useState('');
  const [lairPath, setLairPath] = useState('');
  const [lairDataStatus, setLairDataStatus] = useState('init');
  const [lairName, setLairName] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const workspaceId = useSelector(selectSelectedWorkspaceId);
  const workspaceName = useSelector(selectSelectedWorkspaceName);
  const [numfetchTemplateRetries, setNumFetchTemplateRetries] = useState(0);
  const hideTemplates = SELF_HOSTED; //  || templatesError;
  const [blankLairImgSrc, setBlankLairImgSrc] = useState('https://neat-orchid-cavern.wayscript.cloud/static/images/blank_lair_readme.jpg');

  useEffect(() => {
    const handleFetch = async() => {
      if (selectedTemplate === 'new_lair_selection') return;
      const templateLairId = templatesData[selectedTemplate].lair_id;
      setLairDataStatus('pending');
      const lairData = await dispatch(fetchPublicLairData(templateLairId));
      if (!lairData.error) {
        const {readme, lair_path} = lairData.payload;
        setReadmeContent(readme);
        setLairPath(lair_path);
        setLairDataStatus('idle');
      } else {
        // error fetching lair data
        setLairDataStatus('rejected');
      }
    };
    handleFetch();
  }, [selectedTemplate]);

  const fetchTemplates = async(source = 'auto') => {
    setTemplatesError(false);
    const maxRetries = 5;
    const fetch = async(retry = 0) => {
      const data = await dispatch(fetchLairTemplates());
      if (data.error) {
        if (retry < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, 500 * retry));
          fetch(retry + 1);
        } else {
          if (source === 'manual') setNumFetchTemplateRetries(numfetchTemplateRetries + 1);
          setTemplatesError(true);
        }
        return;
      }
      setTemplatesData(processTemplatesData(data.payload));
    };
    fetch();
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const processTemplatesData = (data) => {
    const {sections, templates} = data;
    const root = {
      id: 'root',
      name: 'root',
      children: [],
      is_directory: true,
    };
    const entities = {
      'root': root,
    };

    sections.forEach(section => {
      const sectionEntity = {
        id: section.id,
        parent: 'root',
        name: section.display,
        is_directory: true,
        children: [],
      };
      templates.forEach(template => {
        if (template.section_id === section.id) {
          const templateEntity = {
            id: template.id,
            name: template.display,
            icon: template.icon,
            lair_id: template.lair_id,
            parent: template.section_id,
            children: [],
          };
          sectionEntity.children.push(template.id);
          entities[template.id] = templateEntity;
        }
      });
      entities[section.id] = sectionEntity;
      root.children.push(section.id);
    });

    return entities;
  };

  const createLair = async(e, value) => {
    if (disabled || !value) return;
    setPending(true);
    const data = await dispatch(fetchCreateLair(value));
    const {error, payload} = data;
    if (error) {
      setPending(false);
      const errorMessage = typeof payload === 'string'
        ? payload // custom reject value from thunkAPI.rejectWithValue
        : error.message;
      setError(errorMessage);
    } else {
      setPending(false);
      close();
      dispatch(openLair(data.payload[0].id));
    }
  };
  const debounceCreateLair = debounce(createLair, 500);
  const handleKeyEnter = (e) => {
    if (selectedTemplate === 'new_lair_selection') {
      if (inputRef.current.value && e.key === 'Enter') debounceCreateLair(null, inputRef.current.value.replaceAll(' ', '-'));
    } else if (selectedTemplate) {
      if (inputRef.current.value && e.key === 'Enter' && lairDataStatus === 'idle' && !pending) handleClone();
    }
  };

  const handleClone = async() => {
    analytics.track(analytics.lairCreatedEvent, {
      from: 'template',
      template: selectedTemplate,
    });
    const newLairName = inputRef.current.value;
    setPending(true);
    const data = await dispatch(fetchClonePublicLair({source: lairPath, destination: workspaceName, lairName: newLairName && newLairName.replaceAll(' ', '-'), workspaceId: workspaceId}));
    if (data.error) {
      setError(data?.error?.message || 'Something went wrong cloning');
      return;
    }
    await dispatch(syncFiles({pullReady: true}));
    setPending(false);
    close();
    window.location.pathname = `/workspaces/${workspaceName}/lairs/${newLairName}/develop/`;
  };

  useEffect(() => {
    return () => {
      if (inputRef.current) {
        dispatch(updateModalData({which: 'create_lair', data: {name: inputRef.current.value}}));
      }
    };
  }, []);

  const handleClick = (e, id) => {
    if (id === 'new_lair_selection') setLairDataStatus('init');
    setSelectedTemplate(id);
  };

  const handleOnSubmit = (e) => {
    if (selectedTemplate === 'new_lair_selection') {
      debounceCreateLair(e, inputRef.current.value);
    } else if (selectedTemplate) {
      if (inputRef.current.value && lairDataStatus !== 'pending') handleClone();
    }
  };

  const renderHeader = () => {
    if (selectedTemplate === 'new_lair_selection') return 'Blank Lair';
    const selectedTemplateData = templatesData[selectedTemplate];
    const sectionData = templatesData[selectedTemplateData.parent];
    return `${sectionData.name} / ${selectedTemplateData.name}`;
  };

  return (
    <Modal
      data-testid='create-lair-modal'
      variant={colorMode}
      isOpen={true}
      onClose={close}
      isCentered
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent p={0} w='100vw' maxWidth={hideTemplates ? '25rem' : '52rem'}
        h={hideTemplates ? (error ? '19rem' : '16rem') : '38rem'} maxHeight='80vh'>
        {/* <ModalCloseButton /> */}
        <ModalBody p={0} height='100%' width='100%' display='flex'>
          {!hideTemplates && <Flex direction='column' flex='0 0 65%'>
            <Box px={4} pt={4} pb={4} borderBottom='1px solid rgba(255, 255, 255, 0.05)' overflow='auto' flex='0 0 auto'><Heading size='md'>{renderHeader()}</Heading></Box>
            <Box overflow='auto' flex='1 1 0'>
              {lairDataStatus === 'idle' && selectedTemplate !== 'new_lair_selection' &&
              <Markdown
                px={4}
                boxCss={{img: {maxWidth: '100%', borderRadius: '0.4rem'}}}
                content={readmeContent}
              />}
              {lairDataStatus === 'pending' && selectedTemplate !== 'new_lair_selection' && <Flex justify='center' mt={10}><Spinner /></Flex>}
              {lairDataStatus === 'rejected' && selectedTemplate !== 'new_lair_selection' && <Flex mt="2" color={`${colorMode}.red.500`} justify='center'>{'something went wrong fetching readme'}</Flex>}
              {selectedTemplate === 'new_lair_selection' &&
                  <Flex direction='column' px={4} pt={4}>
                    <Image src={blankLairImgSrc} borderRadius='0.4rem' height='207px' width='auto'
                      onError={() => {
                        setBlankLairImgSrc('/blank_lair_readme.jpg');
                      }}
                    ></Image>
                    <Heading size='md' mt={4} mb={2}>Create a blank Lair from scratch.</Heading>
                    <Text>Lairs are flexible, containerized development environments configured by your Ops team that you spin up in a single click. They come stocked with all sorts of handy tools like a cron scheduler, terminal and IDE, and endpoint configuration.</Text>
                  </Flex>
              }
            </Box>
          </Flex>}
          <Flex direction='column' borderLeft='1px solid rgba(255, 255, 255, 0.05)' h='100%' flex='1 1 auto'>
            <Flex flex='0 0 auto' pt={4} borderBottom='1px solid rgba(255, 255, 255, 0.05)' pl={4} pb={4}><Heading size='md'>New Lair</Heading></Flex>
            {!hideTemplates && <Flex direction='column' flex='5 1 auto' overflow='auto'>
              <LairTemplate id={'new_lair_root'} grow="0" entities={{
                new_lair_root: {
                  id: 'new_lair_root',
                  name: 'New Lair Rooot',
                  children: ['new_lair_selection'],
                  is_directory: true,
                },
                new_lair_selection: {
                  id: 'new_lair_selection',
                  name: 'Blank',
                  icon: 'lair',
                  children: [],
                },
              }} borderRadius={0} background='none' isRoot={true} indent={-1.0} onClick={handleClick} selectedTemplate={selectedTemplate} disableContextMenu={true} />
              <Text flex='0 0 auto' size='sm' mt={3} color={'grey'} pl={4}>Or start from a template</Text>
              <Flex overflow='auto' direction='column' flex='1 1 auto' mt={3}>
                {templatesData &&
                    <LairTemplate id={'root'} entities={templatesData} isRoot={true} indent={-1.0} onClick={handleClick} selectedTemplate={selectedTemplate} expandedDefault={true} disableContextMenu={true} />
                }
                {!templatesData &&
                  <>
                    {templatesError
                      ? <Flex mt="2" color={`${colorMode}.grey.100`} direction="column" align='center' w='100%'>
                        <p>{'Lair Templates not found '}</p>
                        {numfetchTemplateRetries < 1
                          ? <Button
                            m={2}
                            flex='0 0 auto'
                            w="100%"
                            variant="primary-outline"
                            onClick={e => fetchTemplates('manual')}
                          >
                            Retry
                          </Button>
                          : <p>We are looking into the issue</p>
                        }
                      </Flex>
                      : <Flex justify='center' w='100%' flex='1 1 8rem'><Spinner/></Flex>
                    }
                  </>
                }
              </Flex>
            </Flex>}
            <Flex direction='column' px={4} pb={4} borderTop='1px solid rgba(255, 255, 255, 0.05)' flex='1 0 11rem' justifyContent='space-between'>
              <Flex direction='column'>
                <FormLabel userSelect="none" mt={3}>Name</FormLabel>
                <Input
                  flex='0 0 auto'
                  ref={inputRef}
                  data-testid='create-lair-modal-input'
                  defaultValue={data ? (data.name || '') : ''}
                  mt={2}
                  background={`${colorMode}.bg.700`}
                  type="text"
                  isRequired
                  onChange={e => {
                    const name = e.target.value;
                    if (name) {
                      setDisabled(false);
                      setLairName(name);
                    }
                  }}
                  onKeyDown={e => handleKeyEnter(e)}
                />
                {error &&
                  <FormLabel mt="2" color={`${colorMode}.red.500`}>{error}</FormLabel>
                }
                {lairName && lairName.includes(' ') &&
                  <Flex color={colors.warning[500]} mb={3} css={{
                    '> *': {
                      marginTop: '0.75rem',
                    },
                  }} flexWrap='wrap' align='center'><IconCaution height='1rem' color={colors.warning[500]}/><Box ml={2}>{'Will be created as'}</Box><Code ml={2}>{lairName.replaceAll(' ', '-')}</Code></Flex>
                }
              </Flex>
              <Button
                mt={2}
                flex='0 0 auto'
                w="100%"
                variant="primary"
                onClick={e => handleOnSubmit(e)}
                isLoading={pending}
                disabled={lairDataStatus === 'pending' || pending}
              >
                  Create Lair
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ModalCreateLair.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalCreateLair;
