import {useBreakpointValue} from '@chakra-ui/react';
import {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectPanels} from '../features/layout/PanelBottomSlice';
import {selectPanelLeftStatus, setPanelLeft} from '../features/layout/PanelLeftSlice';

export const useDefaultPanels = () => {
  const variant = useBreakpointValue({base: 15, md: 20, xl: 25});
  const variantRef = useRef(null);
  const dispatch = useDispatch();
  const panels = JSON.parse(useSelector(selectPanels));
  const panelLeftStatus = useSelector(selectPanelLeftStatus);

  if (variantRef.current === undefined && variant) {
    if (panelLeftStatus === 'init') {
      dispatch(setPanelLeft({
        ...panels.left,
        width: variant,
      }));
    }
  }
  variantRef.current = variant;
};
