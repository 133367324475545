import React, {useState} from 'react';
import {
  Button,
  Text,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorMode,
  Modal,
  ModalOverlay,
  Input,
  FormLabel,
  Flex,
  Link,
} from '@chakra-ui/react';
import {useTheme} from '@emotion/react';
import PropTypes from 'prop-types';
import {CountrySelect} from '../settings/CountrySelect';
import {IconClose, IconChevronRight} from '../../components/Icons';
import {
  fetchUpdateWorkspaceBilling,
  selectSelectedWorkspaceId,
  fetchUpgradeWorkspaceBilling,
} from '../../app/WorkspacesSlice';
import {useDispatch, useSelector} from 'react-redux';

const ModalBilling = ({
  close,
  data,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  const [step, setStep] = useState('cardInfo');
  const [confirmError, setConfirmError] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    name: '',
    num: '',
    exp: '',
    security: '',
    country: '',
    postal: '',
  });
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const workspaceId = useSelector(selectSelectedWorkspaceId);

  const validateCardDetails = () => {
    // check that no fields are empty
    if (!cardInfo.name || !cardInfo.num || !cardInfo.exp || !cardInfo.security || !cardInfo.country || !cardInfo.postal) {
      setEmptyFieldError(true);
      return false;
    } else return true;
  };

  const updateBilling = () => {
    setEmptyFieldError(false);
    const billingData = dispatch(fetchUpdateWorkspaceBilling({id: workspaceId, cardInfo: cardInfo}));
    if (!billingData.error) {
      setErrors({});
      if (data.inTrial) setStep('confirm');
      else close();
    } else setErrors(billingData);
  };

  const handleConfirm = () => {
    const upgradeData = dispatch(fetchUpgradeWorkspaceBilling({id: workspaceId, seats: data.seats}));
    if (!upgradeData.error) {
      setConfirmError(false);
      close();
    } else setConfirmError(true);
  };

  const handlePaymentDetails = () => {
    if (validateCardDetails()) {
      updateBilling();
    }
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setCardInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCountrySelect = (val, label) => {
    setCardInfo(prevState => ({
      ...prevState,
      [label]: val,
    }));
  };

  const handleExpDateFormat = e => {
    if (e.target.value.indexOf('.') >= 0 || e.target.value.length > 5) { return; }
    if (e.target.value.length === 2 && cardInfo.exp.length === 1) { e.target.value += '/'; }
    handleChange(e);
  };

  const errorStyle = {background: 'rgba(211,84,84,.15)', border: 'none', padding: '.5rem'};
  const labelErrorStyle = {color: `${colors.error[500]}`, height: '0'};
  const generalErrorStyle = {
    color: `${colors.error[500]}`,
    marginBottom: '15px',
    alignItems: 'center',
    fontWeight: 'bold',
    background: 'rgba(211,84,84,.15)',
    padding: '5px',
    borderRadius: '5px',
  };
  const inputStyle = {
    background: `${colorMode}.grey.800`,
    border: 'none',
    height: '2.1rem',
    padding: '.5rem',
  };

  const creditCardInfo =
    <Flex direction="column" color={`${colorMode}.grey.200`}>
      {errors && errors.general && <Flex sx={generalErrorStyle}><IconClose width='16px' height='16px' style={{marginRight: '.5rem'}} color={colors.error[500]} />{errors.general}</Flex>}
      {emptyFieldError && <Flex sx={generalErrorStyle}><IconClose width='16px' height='16px' style={{marginRight: '.5rem'}} color={colors.error[500]} />All fields required</Flex>}
      <Text as="span" mb="2">Cardholder Name</Text>
      <Input
        id="card_name"
        type="text"
        placeholder="First Last"
        value={cardInfo.name}
        onChange={handleChange}
        name="name"
        sx={errors && errors.name ? errorStyle : inputStyle}
      />
      {errors && errors.name &&
        <FormLabel sx={labelErrorStyle} htmlFor="card_name">
          {errors.name}
        </FormLabel>
      }
      <Text as="span" mb="2" mt="4">Card Number</Text>
      <Input
        id="card_num"
        type="text"
        placeholder="1234 5678 9012 3456"
        value={cardInfo.num}
        onChange={handleChange}
        name="num"
        sx={errors && errors.num ? errorStyle : inputStyle}
      />
      {errors && errors.num &&
        <FormLabel sx={labelErrorStyle} htmlFor="card_num">
          {errors.num}
        </FormLabel>
      }
      <Flex mt="4">
        <Flex mr="4" style={{width: '60%'}} direction="column">
          <Text as="span" my="2">Exp. Date</Text>
          <Input
            id="card_exp"
            type="text"
            placeholder="MM/YY"
            value={cardInfo.exp}
            onChange={e => handleExpDateFormat(e)}
            name="exp"
            sx={errors && errors.exp ? errorStyle : inputStyle}
          />
          {errors && errors.exp &&
            <FormLabel sx={labelErrorStyle} htmlFor="card_exp">
              {errors.exp}
            </FormLabel>
          }
        </Flex>
        <Flex style={{width: '37%'}} direction="column">
          <Text as="span" my="2">Security Code</Text>
          <Input
            id="card_security"
            type="text"
            placeholder="123"
            value={cardInfo.security}
            onChange={handleChange}
            name="security"
            background={`${colorMode}.grey.800`}
            style={errors && errors.security ? errorStyle : inputStyle}
          />
          {errors && errors.security &&
            <FormLabel sx={labelErrorStyle} htmlFor="card_exp">
              {errors.security}
            </FormLabel>
          }
        </Flex>
      </Flex>
      <Flex mt="4">
        <Flex mr="4" style={{width: '60%'}} direction="column">
          <Text as="span" my="2">Country</Text>
          <CountrySelect handleChange={handleCountrySelect} value={cardInfo.country} />
        </Flex>
        <Flex style={{width: '37%'}} direction="column">
          <Text as="span" my="2">Postal Code</Text>
          <Input
            id="card_postal"
            type="text"
            placeholder="12345"
            value={cardInfo.postal}
            onChange={handleChange}
            name="postal"
            background={`${colorMode}.grey.800`}
            style={errors && errors.postal ? errorStyle : inputStyle}
          />
          {errors && errors.postal &&
            <FormLabel sx={labelErrorStyle} htmlFor="card_postal">
              {errors.postal}
            </FormLabel>
          }
        </Flex>
      </Flex>
    </Flex>;

  const confirmPayment = <Flex direction="column" color={`${colorMode}.grey.200`}>
    <hr style={{margin: '1.5rem 0'}} />
    <Text>You will be billed:</Text>
    <Text><Text as="span" fontWeight="bold" color={`${colorMode}.grey.50`}>{data.seats}</Text> seats x $10</Text>
    <hr style={{margin: '1.5rem 0'}} />
    <Text fontWeight="bold" color={`${colorMode}.grey.50`} fontSize="1.2rem" mb={1}>${data.seats * 10}/mo</Text>
    <Text>On {data.card.cardType} ending in {data.card.cardDigits}</Text>
    <Text>Renews on {data.renewalDate}</Text>
    {confirmError && <Text sx={labelErrorStyle} htmlFor="card_postal">Something went wrong.</Text>}
  </Flex>;

  return (
    <Modal
      variant={colorMode}
      isOpen={true}
      onClose={close}
      isCentered
    >
      <ModalOverlay />
      <ModalContent minW={'500px'}>
        <ModalHeader>{data.inTrial ? 'Upgrade to a paid plan' : 'Update payment details'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data.inTrial &&
        <>
          <Flex align="center" mb={3}>
            <Text as="span" pr={2} color={step === 'cardInfo' ? `${colorMode}.grey.50` : `${colorMode}.grey.300`}>Payment details</Text>
            <IconChevronRight width='13px' height='13px' color={colors[colorMode].grey[300]} />
            <Text as="span" pl={2} color={step === 'confirm' ? `${colorMode}.grey.50` : `${colorMode}.grey.300`}>Confirm</Text>
          </Flex>
          <Text color={`${colorMode}.grey.200`}>Paid plans are billed at $10 per user per month and include 500Mb of storage and 100 hours of runtime. <Link color={`${colorMode}.primary.500`}>Boosts</Link> are available to increase capacity.</Text>
          {step === 'cardInfo' && <Text mt={3} mb={4} color={`${colorMode}.grey.200`}>Enter your payment details to upgrade</Text>}
        </>
          }
          {step === 'cardInfo' ? creditCardInfo : confirmPayment}
        </ModalBody>
        <ModalFooter>
          <Button
            w="30%"
            variant="white-outline"
            onClick={() => close()}
          >
              Cancel
          </Button>
          {step === 'cardInfo'
            ? <Button
              ml={3}
              w="30%"
              variant="primary"
              onClick={handlePaymentDetails}
            >
              <Text>{data.inTrial ? 'Next' : 'Update'}</Text>
            </Button>
            : <Button
              ml={3}
              w="30%"
              variant="primary"
              onClick={handleConfirm}
            >
              <Text>Upgrade</Text>
            </Button>
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalBilling.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalBilling;
