import PropTypes from 'prop-types';
import {useState, useEffect, useRef} from 'react';
import {
  Flex,
  Text,
  useColorMode,
  Box,
  Collapse,
  useTheme,
  Spinner,
  Button,
} from '@chakra-ui/react';
import DropdownMenu from '../../components/DropdownMenu';
import {
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconPlus,
  IconFile,
} from '../../components/Icons';
import {getFilePathExtensionIcon} from './helpers';
import {FileInput} from '../../components/Inputs';
import Icon from '../../components/Icon';

const File = ({
  id,
  isRoot,
  onClick = null,
  name = null,
  editable = true,
  children,
  indent = 0,
  expandable = false,
  icon = null,
  status = 'normal',
  handleRename = null,
  handleDelete = null,
  handleAddFile = null,
  handleDuplicate = null,
  handleUpload = null,
  status_properties,
  addingFile,
  pending,
  handleAddingFile,
  error,
  fileError,
  setError,
  setRenameError,
  setAddingFile,
  renameError,
  filePending,
  renaming,
  setRenaming,
  handleDragFileStart,
  handleDragFileDrop,
  handleDragOverFile,
  isDraggable,
  expanded,
  setExpanded,
  isDirectory,
  selected,
  isLair,
  secretId,
  envId,
  readmeId,
  isTriggerFile,
  path,
  preSelected,
  isSearch,
  isFlat,
  changed = false,
  tabIndex,
  pendingUploads,
  isMultipleFilesSelected,
  deleteError,
  useFilePathIcon = true,
  defaultIcon,
  flex = '1 0 auto',
  grow = '1',
  handleDownload,
  disableContextMenu,
}) => {
  const theme = useTheme();
  const inputRef = useRef(null);
  const focusHelper = useRef(true);
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  const indentArrr = Array.from({length: indent}, (_, index) => index + 1);
  const [draggingOver, setDraggingOver] = useState(false);
  const bgColor = draggingOver || preSelected ? `${colorMode}.bg.300` : selected && !isSearch ? `${colorMode}.primary.500` : 'transparent';
  let contextMenu = null;
  useEffect(() => {
    if (inputRef.current && focusHelper.current) {
      inputRef.current.focus();
      focusHelper.current = false;
    }
  });
  if (onClick || handleRename || handleDelete || (!isRoot && handleDuplicate) || isTriggerFile) {
    if (!contextMenu) contextMenu = {title: '', items: []};
    const section = {title: '', items: []};
    if (onClick && (!selected || (selected && !isMultipleFilesSelected))) {
      section.items.push({
        display: 'Open',
        onClick: onClick,
      });
    }
    if (handleRename) {
      section.items.push({
        display: 'Rename',
        onClick: () => { setRenaming(true); },
        dataCy: 'rename-file',
      });
    }
    if (handleDelete) {
      section.items.push({
        display: 'Delete',
        onClick: handleDelete,
      });
    }
    if (handleDuplicate) {
      section.items.push({
        display: 'Duplicate',
        onClick: handleDuplicate,
      });
    }
    if (handleDownload) {
      section.items.push({
        display: 'Download',
        onClick: handleDownload,
        link: {
          className: 'download-link',
        },
        disableClose: true,
      });
    }
    contextMenu.items.push(section); // add new section
  }
  if (handleAddFile && !isLair) {
    if (!contextMenu) contextMenu = {title: '', items: []};
    const section = {title: '', items: []};
    if (handleAddFile) {
      section.items.push({
        display: 'New file',
        onClick: () => {
          handleAddingFile({type: 'file', show: true});
          setExpanded(true);
        },
      });
    }
    if (handleAddFile && !isLair) {
      section.items.push({
        display: 'New folder',
        onClick: () => {
          handleAddingFile({type: 'folder', show: true});
          setExpanded(true);
        },
      });
    }
    if (handleUpload) {
      section.items.push({
        display: 'Upload File',
        type: 'upload',
        onClick: handleUpload,
      });
    }
    if (isRoot && isLair) {
      section.items.push({
        display: <span style={{color: secretId ? 'grey' : 'white', cursor: secretId && 'default'}}>.secrets</span>,
        actionIcon: !secretId ? <IconPlus width='1rem' height='1rem'/> : <IconCheck color={`${secretId ? 'grey' : 'white'}`} width='1rem' height='1rem'/>,
        onClick: () => { if (!secretId) handleAddingFile({type: 'secret', show: true}); },
      });
      section.items.push({
        display: <span style={{color: envId ? 'grey' : 'white', cursor: envId && 'default'}}>.env</span>,
        actionIcon: !envId ? <IconPlus width='1rem' height='1rem'/> : <IconCheck color={`${envId ? 'grey' : 'white'}`} width='1rem' height='1rem'/>,
        onClick: () => { if (!envId) handleAddingFile({type: 'env'}); },
      });
      section.items.push({
        display: <span style={{color: readmeId ? 'grey' : 'white', cursor: readmeId && 'default'}}>README.md</span>,
        actionIcon: !readmeId ? <IconPlus width='1rem' height='1rem'/> : <IconCheck color={`${readmeId ? 'grey' : 'white'}`} width='1rem' height='1rem'/>,
        onClick: () => { if (!readmeId) handleAddingFile({type: 'readme'}); },
      });
    }
    contextMenu.items.push(section); // add new section
  }

  let FileIcon;
  if (useFilePathIcon && (!isDirectory || isLair)) {
    FileIcon = (props) => getFilePathExtensionIcon(icon, props, theme, colorMode);
  } else if (!useFilePathIcon && (!isDirectory || isLair)) {
    // eslint-disable-next-line react/display-name
    FileIcon = (props) => <Icon name={icon} {...props} theme={theme} colorMode={colorMode} defaultIcon={defaultIcon || <IconFile />}/>;
  }

  const handleRenameBlur = e => {
    if (e.target.value !== name && !renameError && (/\S/.test(e.target.value))) {
      handleRename(e.target.value);
    } else {
      setRenaming(false);
      focusHelper.current = true;
    }
  };
  const handleRenameKeyDown = e => {
    if (e.key === 'Escape') {
      setRenaming(false);
      focusHelper.current = true;
    }
  };
  const handleRenameKeyPress = e => {
    if (e.key === 'Enter') handleRenameBlur(e);
  };
  const handleCreateFileBlur = e => {
    focusHelper.current = true;
    if (e.target.value !== '' && (/\S/.test(e.target.value))) {
      handleAddFile(e.target.value, addingFile.type === 'folder');
    } else {
      setAddingFile(null);
      setError('');
    }
  };
  const handleCreateFileKeyDown = e => {
    if (e.key === 'Escape') {
      setAddingFile(null);
    }
  };
  const handleCreateFileKeyPress = e => {
    if (e.key === 'Enter') handleCreateFileBlur(e);
  };

  const handleFileClick = (e) => {
    if (!renaming && onClick) onClick(e, id);
    else return undefined;
  };

  return (name === null ? null
    : <Flex
      className="FILEROOT"
      grow={grow}
      direction="column"
      background={isSearch ? bgColor : 'none'}
    >
      {!isRoot && <DropdownMenu
        menuId={`dropdown-menu-${id}`}
        appendTo={document.body}
        settings={contextMenu}
        colorMode={colorMode}
        event="contextmenu"
        style={{flex, overflow: isRoot || (isRoot && isLair) ? 'auto' : ''}}
        placement={isRoot ? 'right' : 'bottom'}
        iconSide={isRoot && isLair && 'right'}
        disableContextMenu={disableContextMenu}
      >
        <Button
          borderRadius={0}
          className="draggable-file"
          id={id}
          draggable={isDraggable}
          onDrop={isDirectory ? e => { e.stopPropagation(); handleDragFileDrop(e, id); setDraggingOver(false); } : null}
          onDragStart={e => handleDragFileStart(e)}
          onDragOver={e => { handleDragOverFile(e); if (isDirectory) setDraggingOver(true); } }
          data-testid={`file-${id}`}
          onClick={handleFileClick}
          background={bgColor}
          position="relative"
          width="100%"
          height="2.2rem"
          align="center"
          cursor={onClick || expandable ? 'pointer' : 'default'}
          onDragLeave={e => { e.preventDefault(); e.stopPropagation(); setDraggingOver(false); }}
          sx={{
            'div:first-of-type': {
              marginLeft: isFlat ? '0.8rem' : '0',
            },
            '>*': {
              margin: isFlat ? '0 0.4rem' : '0',
            },
            justifyContent: 'left !important',
            paddingLeft: `${isSearch && '0 !important'}`,
          }}
          _hover={{
            background: !selected && `${colorMode}.bg.300`,
          }}
          tabIndex={tabIndex}
          _focus={{
            outline: 'none !important',
            boxShadow: 'none !important',
            borderLeft: `2px solid ${colors[colorMode].grey[100]}`,
          }}
        >
          {indentArrr.map((ind, i) => <Flex
            key={i}
            position={!isFlat ? 'absolute' : 'relative'}
            left={`${Number(ind) * 1.5 - 0.5}rem`}
            height="100%"
            width="1rem"
            justify="center"
          >
            <Box
              background={`${colorMode}.grey.500`}
              width=".0625rem"
            >
            </Box>
          </Flex>,
          ) }
          {FileIcon && !pending &&
              <Flex
                position={!isFlat ? 'absolute' : 'relative'}
                width="1rem"
                height="1rem"
                left={!isFlat ? `${1 + Number(indent) * 1.5}rem` : '0'}
                align="center"
                justify="center"
                borderRadius=".5rem"
              >
                <FileIcon width={theme.fontSizes.lg} height={theme.fontSizes.lg}/>
              </Flex>
          }
          {expandable &&
              <Flex
                width={theme.fontSizes.lg}
                height={theme.fontSizes.lg}
                position="absolute"
                left={`${1 + Number(indent) * 1.5}rem`}
                align="center"
                justify="center"
                cursor="pointer"
              >
                {expanded
                  ? <IconChevronDown width={theme.fontSizes.sm} height={theme.fontSizes.sm}/>
                  : <IconChevronRight width={theme.fontSizes.sm} height={theme.fontSizes.sm}/>
                }
              </Flex>
          }
          {!renaming
            ? <Flex
              position={!isFlat ? 'absolute' : 'relative'}
              align="center"
              justify="space-between"
              left={!isFlat ? `${2.5 + Number(indent * 1.5)}rem` : '0'}
              w={!isFlat ? `calc(100% - ${2.5 + Number(indent * 1.5)}rem)` : 'auto'}
            >
              <Text
                userSelect="none"
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {filePending ? filePending.name : name}
              </Text>
              {filePending &&
                  <Spinner data-testid='file-spinner' size="sm" color={colors[colorMode].grey[300]} mr={3} />
              }
              {changed &&
                  <Text
                    position={'absolute'}
                    width="1rem"
                    height="1rem"
                    right=".5rem"
                    align="center"
                    justify="center"
                    fontWeight="bold"
                    marginBottom=".5rem"
                    color={`${colorMode}.white.500`}
                  >
                    C
                  </Text>
              }
            </Flex>
            : <FileInput
              inputRef={inputRef}
              defaultValue={name}
              onBlur={handleRenameBlur}
              onKeyDown={handleRenameKeyDown}
              onKeyPress={handleRenameKeyPress}
              error={renameError}
              setError={setRenameError}
              indent={indent}
              errorTime={4000}
              selected={selected}
            />
          }
          {(fileError || deleteError) &&
              <Box
                pos='absolute'
                px={3}
                top='1.5rem'
                background={colors.warning[500]}
                color={colors[colorMode].grey[900]}
                borderRadius='3px'
                zIndex="10"
              >
                {fileError || deleteError}
              </Box>
          }
          {path &&
              <Text
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                color={colors[colorMode].grey[300]}
              >
                {path}
              </Text>
          }
        </Button>
      </DropdownMenu>}
      {children && <Collapse onDrop={isDirectory ? e => { e.stopPropagation(); handleDragFileDrop(e, id); } : null}
        in={expanded || isRoot}
        data-testid={`file-collapse-${id}`}>
        <>
          {pending &&
            <Flex
              align="center"
              justify="space-between"
              pl={`${2.5 + Number(indent * 1.5)}rem`}
            >
              <Text>{pending.name}</Text>
              <Spinner data-testid='file-spinner' size="sm" color={colors[colorMode].grey[300]} />
            </Flex>
          }
          {pendingUploads && pendingUploads.map(name => {
            return (
              <>
                { !name ? null
                  : <Flex
                    key={name + '-pending-upload'}
                    align="center"
                    justify="space-between"
                    pl={`${2.5 + Number(indent * 1.5)}rem`}
                  >
                    <Text>{name}</Text>
                    <Spinner data-testid='file-spinner' size="sm" color={colors[colorMode].grey[300]} />
                  </Flex>
                }
              </>
            );
          })
          }
          {children}
          {addingFile && addingFile.show &&
            <FileInput
              inputRef={inputRef}
              error={error}
              indent={indent}
              onBlur={handleCreateFileBlur}
              onKeyDown={handleCreateFileKeyDown}
              onKeyPress={handleCreateFileKeyPress}
              setError={setError}
              icon={addingFile && (addingFile.type === 'file' ? <IconFile width={theme.fontSizes.lg} height={theme.fontSizes.lg}/> : <IconChevronRight width={theme.fontSizes.sm} height={theme.fontSizes.sm} />)}
              errorTime={4000}
            />
          }
        </>
      </Collapse>}
    </Flex>
  );
};

File.propTypes = {
  id: PropTypes.string,
  isRoot: PropTypes.bool,
  indent: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  editable: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.node, // icon component
    PropTypes.string, // icon name
  ]),
  status: PropTypes.string,
  handleRename: PropTypes.func,
  handleDelete: PropTypes.func,
  handleAddFile: PropTypes.func,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  path: PropTypes.string,
  changed: PropTypes.bool,
  deleteError: PropTypes.string,
  flex: PropTypes.string,
};

export default File;
