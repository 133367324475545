import React, {useState} from 'react';
import {
  Button,
  Text,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorMode,
  Modal,
  ModalOverlay,
  useTheme,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
  fetchBoostWorkspace,
  selectSelectedWorkspaceId,
} from '../../app/WorkspacesSlice';
import {useDispatch, useSelector} from 'react-redux';

const ModalBoost = ({
  close,
  data,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const workspaceId = useSelector(selectSelectedWorkspaceId);

  const handleConfirm = (boosts = 0) => {
    const boost = dispatch(fetchBoostWorkspace({id: workspaceId, boosts}));
    if (!boost.error) {
      setError(false);
      close();
    } else setError(true);
    close();
  };

  return (
    <Modal
      variant={colorMode}
      isOpen={true}
      onClose={close}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure you want to {data.boost === 'add' ? 'Boost' : 'remove the Boost from'} your Workspace?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data.boost === 'add' && <Text>Your workspace resources will be boosted immediately, and we will charge $100 to your existing payment method.</Text>}
          {error && <Text color={colors.error[500]}>Something went wrong</Text>}
        </ModalBody>
        <ModalFooter>
          <Button
            w="30%"
            variant="white-outline"
            onClick={() => close()}
          >
              Cancel
          </Button>
          <Button
            data-testid='delete-file-confirm-btn'
            ml={3}
            w="35%"
            variant={data.boost === 'add' ? 'primary' : 'red'}
            onClick={() => handleConfirm(data.boost === 'add' ? 1 : 0)}
          >
            <Text>{data.boost === 'add' ? 'Boost' : 'Remove boost'}</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalBoost.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalBoost;
