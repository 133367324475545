import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  selectCurrentModal,
} from './ModalSlice';
import {
  Button,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorMode,
  Modal,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {useTheme} from '@emotion/react';

const ModalFileSave = () => {
  const modal = useSelector(selectCurrentModal) || {};
  const {data} = modal;
  const {
    accept,
    deny,
    cancel,
    acceptText = 'Save',
    denyText = "Don't Save",
    cancelText = 'Cancel',
    header = 'This file has unsaved changes',
  } = data;
  const initialFocusRef = useRef(null);
  const {colorMode} = useColorMode();
  const [error] = useState(null);
  const theme = useTheme();

  const handleSave = async() => {
    if (accept) accept();
  };

  const handleDontSave = () => {
    if (deny) deny();
  };

  const handleCancel = () => {
    if (cancel) cancel();
  };

  return (
    <Modal
      data-testid='file-save-modal'
      variant={colorMode}
      isOpen={true}
      onClose={handleCancel}
      isCentered
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader userSelect="none">{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error &&
            <FormLabel userSelect="none" mt="2" color={`${colorMode}.red.500`}>{error}</FormLabel>
          }
        </ModalBody>
        <ModalFooter>
          <Flex direction='column' w='100%'>
            <Button
              w="100%"
              variant="primary"
              onClick={handleSave}
              my={`${theme.space[3]}`}
            >
              {acceptText}
            </Button>
            <Button
              w="100%"
              variant="primary"
              onClick={handleDontSave}
              my={`${theme.space[3]}`}
            >
              {denyText}
            </Button>
            <Button
              w="100%"
              variant="primary"
              onClick={handleCancel}
              my={`${theme.space[3]}`}
            >
              {cancelText}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ModalFileSave.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalFileSave;
