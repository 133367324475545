import React from 'react';
import Selector from '../../components/Selector';

const CountrySelect = ({handleChange, value}) => {
  return (
    <Selector
      handleSelect={e => handleChange(e, 'country')}
      value={value}
      options={[
        {value: 'Afganistan', label: 'Afganistan'},
        {value: 'Albania', label: 'Albania'},
        {value: 'Algeria', label: 'Algeria'},
        {value: 'American Samoa', label: 'American Samoa'},
        {value: 'Andorra', label: 'Andorra'},
        {value: 'Angola', label: 'Angola'},
        {value: 'Anguilla', label: 'Anguilla'},
        {value: 'Antigua & Barbuda', label: 'Antigua & Barbuda'},
        {value: 'Argentina', label: 'Argentina'},
        {value: 'Armenia', label: 'Armenia'},
        {value: 'Aruba', label: 'Aruba'},
        {value: 'Australia', label: 'Australia'},
        {value: 'Austria', label: 'Austria'},
        {value: 'Azerbaijan', label: 'Azerbaijan'},
        {value: 'Bahamas', label: 'Bahamas'},
        {value: 'Bahrain', label: 'Bahrain'},
        {value: 'Bangladesh', label: 'Bangladesh'},
        {value: 'Barbados', label: 'Barbados'},
        {value: 'Belarus', label: 'Belarus'},
        {value: 'Belgium', label: 'Belgium'},
        {value: 'Belize', label: 'Belize'},
        {value: 'Benin', label: 'Benin'},
        {value: 'Bhutan', label: 'Bhutan'},
        {value: 'Bolivia', label: 'Bolivia'},
        {value: 'Bosnia & Herzegovina', label: 'Bosnia & Herzegovina'},
        {value: 'Botswana', label: 'Botswana'},
        {value: 'Brazil', label: 'Brazil'},
        {value: 'Brunei', label: 'Brunei'},
        {value: 'Bulgaria', label: 'Bulgaria'},
        {value: 'Burkina Faso', label: 'Burkina Faso'},
        {value: 'Burundi', label: 'Burundi'},
        {value: 'Cambodia', label: 'Cambodia'},
        {value: 'Cameroon', label: 'Cameroon'},
        {value: 'Canada', label: 'Canada'},
        {value: 'Central African Republic', label: 'Central African Republic'},
        {value: 'Chad', label: 'Chad'},
        {value: 'Chile', label: 'Chile'},
        {value: 'China', label: 'China'},
        {value: 'Colombia', label: 'Colombia'},
        {value: 'Comoros', label: 'Comoros'},
        {value: 'Congo', label: 'Congo'},
        {value: 'Costa Rica', label: 'Costa Rica'},
        {value: "Côte d'Ivoire", label: "Côte d'Ivoire"},
        {value: 'Croatia', label: 'Croatia'},
        {value: 'Cuba', label: 'Cuba'},
        {value: 'Curaco', label: 'Curaco'},
        {value: 'Cyprus', label: 'Cyprus'},
        {value: 'Czech Republic', label: 'Czech Republic'},
        {value: 'Denmark', label: 'Denmark'},
        {value: 'Djibouti', label: 'Djibouti'},
        {value: 'Dominica', label: 'Dominica'},
        {value: 'Dominican Republic', label: 'Dominican Republic'},
        {value: 'Ecuador', label: 'Ecuador'},
        {value: 'Egypt', label: 'Egypt'},
        {value: 'El Salvador', label: 'El Salvador'},
        {value: 'Equatorial Guinea', label: 'Equatorial Guinea'},
        {value: 'Eritrea', label: 'Eritrea'},
        {value: 'Estonia', label: 'Estonia'},
        {value: 'Ethiopia', label: 'Ethiopia'},
        {value: 'Fiji', label: 'Fiji'},
        {value: 'Finland', label: 'Finland'},
        {value: 'France', label: 'France'},
        {value: 'Gabon', label: 'Gabon'},
        {value: 'Gambia', label: 'Gambia'},
        {value: 'Georgia', label: 'Georgia'},
        {value: 'Germany', label: 'Germany'},
        {value: 'Ghana', label: 'Ghana'},
        {value: 'Great Britain', label: 'Great Britain'},
        {value: 'Greece', label: 'Greece'},
        {value: 'Greenland', label: 'Greenland'},
        {value: 'Grenada', label: 'Grenada'},
        {value: 'Guadeloupe', label: 'Guadeloupe'},
        {value: 'Guam', label: 'Guam'},
        {value: 'Guatemala', label: 'Guatemala'},
        {value: 'Guinea', label: 'Guinea'},
        {value: 'Guyana', label: 'Guyana'},
        {value: 'Haiti', label: 'Haiti'},
        {value: 'Honduras', label: 'Honduras'},
        {value: 'Hungary', label: 'Hungary'},
        {value: 'Iceland', label: 'Iceland'},
        {value: 'Indonesia', label: 'Indonesia'},
        {value: 'India', label: 'India'},
        {value: 'Iran', label: 'Iran'},
        {value: 'Iraq', label: 'Iraq'},
        {value: 'Ireland', label: 'Ireland'},
        {value: 'Israel', label: 'Israel'},
        {value: 'Italy', label: 'Italy'},
        {value: 'Jamaica', label: 'Jamaica'},
        {value: 'Japan', label: 'Japan'},
        {value: 'Jordan', label: 'Jordan'},
        {value: 'Kazakhstan', label: 'Kazakhstan'},
        {value: 'Kenya', label: 'Kenya'},
        {value: 'Kiribati', label: 'Kiribati'},
        {value: 'Kuwait', label: 'Kuwait'},
        {value: 'Kyrgyzstan', label: 'Kyrgyzstan'},
        {value: 'Laos', label: 'Laos'},
        {value: 'Latvia', label: 'Latvia'},
        {value: 'Lebanon', label: 'Lebanon'},
        {value: 'Lesotho', label: 'Lesotho'},
        {value: 'Liberia', label: 'Liberia'},
        {value: 'Libya', label: 'Libya'},
        {value: 'Liechtenstein', label: 'Liechtenstein'},
        {value: 'Lithuania', label: 'Lithuania'},
        {value: 'Luxembourg', label: 'Luxembourg'},
        {value: 'Macedonia', label: 'Macedonia'},
        {value: 'Madagascar', label: 'Madagascar'},
        {value: 'Malawi', label: 'Malawi'},
        {value: 'Malaysia', label: 'Malaysia'},
        {value: 'Maldives', label: 'Maldives'},
        {value: 'Mali', label: 'Mali'},
        {value: 'Malta', label: 'Malta'},
        {value: 'Marshall Islands', label: 'Marshall Islands'},
        {value: 'Martinique', label: 'Martinique'},
        {value: 'Mexico', label: 'Mexico'},
        {value: 'Micronesia', label: 'Micronesia'},
        {value: 'Moldova', label: 'Moldova'},
        {value: 'Monaco', label: 'Monaco'},
        {value: 'Mongolia', label: 'Mongolia'},
        {value: 'Montenegro', label: 'Montenegro'},
        {value: 'Morocco', label: 'Morocco'},
        {value: 'Mozambique', label: 'Mozambique'},
        {value: 'Myanmar', label: 'Myanmar'},
        {value: 'Nambia', label: 'Nambia'},
        {value: 'Nauru', label: 'Nauru'},
        {value: 'Nepal', label: 'Nepal'},
        {value: 'Netherlands', label: 'Netherlands'},
        {value: 'New Zealand', label: 'New Zealand'},
        {value: 'Nicaragua', label: 'Nicaragua'},
        {value: 'Niger', label: 'Niger'},
        {value: 'Nigeria', label: 'Nigeria'},
        {value: 'North Korea', label: 'North Korea'},
        {value: 'North Macedonia', label: 'North Macedonia'},
        {value: 'Norway', label: 'Norway'},
        {value: 'Oman', label: 'Oman'},
        {value: 'Pakistan', label: 'Pakistan'},
        {value: 'Panama', label: 'Panama'},
        {value: 'Papua New Guinea', label: 'Papua New Guinea'},
        {value: 'Paraguay', label: 'Paraguay'},
        {value: 'Peru', label: 'Peru'},
        {value: 'Phillipines', label: 'Phillipines'},
        {value: 'Poland', label: 'Poland'},
        {value: 'Portugal', label: 'Portugal'},
        {value: 'Puerto Rico', label: 'Puerto Rico'},
        {value: 'Qatar', label: 'Qatar'},
        {value: 'Romania', label: 'Romania'},
        {value: 'Russia', label: 'Russia'},
        {value: 'Rwanda', label: 'Rwanda'},
        {value: 'St Kitts-Nevis', label: 'St Kitts-Nevis'},
        {value: 'St Lucia', label: 'St Lucia'},
        {value: 'St Vincent & Grenadines', label: 'St Vincent & Grenadines'},
        {value: 'Samoa', label: 'Samoa'},
        {value: 'San Marino', label: 'San Marino'},
        {value: 'Sao Tome & Principe', label: 'Sao Tome & Principe'},
        {value: 'Saudi Arabia', label: 'Saudi Arabia'},
        {value: 'Senegal', label: 'Senegal'},
        {value: 'Serbia', label: 'Serbia'},
        {value: 'Seychelles', label: 'Seychelles'},
        {value: 'Sierra Leone', label: 'Sierra Leone'},
        {value: 'Singapore', label: 'Singapore'},
        {value: 'Slovakia', label: 'Slovakia'},
        {value: 'Slovenia', label: 'Slovenia'},
        {value: 'Solomon Islands', label: 'Solomon Islands'},
        {value: 'Somalia', label: 'Somalia'},
        {value: 'South Africa', label: 'South Africa'},
        {value: 'South Korea', label: 'South Korea'},
        {value: 'Spain', label: 'Spain'},
        {value: 'Sri Lanka', label: 'Sri Lanka'},
        {value: 'Sudan', label: 'Sudan'},
        {value: 'Suriname', label: 'Suriname'},
        {value: 'Sweden', label: 'Sweden'},
        {value: 'Switzerland', label: 'Switzerland'},
        {value: 'Syria', label: 'Syria'},
        {value: 'Tajikistan', label: 'Tajikistan'},
        {value: 'Tanzania', label: 'Tanzania'},
        {value: 'Thailand', label: 'Thailand'},
        {value: 'Togo', label: 'Togo'},
        {value: 'Tonga', label: 'Tonga'},
        {value: 'Trinidad & Tobago', label: 'Trinidad & Tobago'},
        {value: 'Tunisia', label: 'Tunisia'},
        {value: 'Turkey', label: 'Turkey'},
        {value: 'Turkmenistan', label: 'Turkmenistan'},
        {value: 'Tuvalu', label: 'Tuvalu'},
        {value: 'Uganda', label: 'Uganda'},
        {value: 'United Kingdom', label: 'United Kingdom'},
        {value: 'Ukraine', label: 'Ukraine'},
        {value: 'United Arab Erimates', label: 'United Arab Erimates'},
        {value: 'United States of America', label: 'United States of America'},
        {value: 'Uraguay', label: 'Uraguay'},
        {value: 'Uzbekistan', label: 'Uzbekistan'},
        {value: 'Vanuatu', label: 'Vanuatu'},
        {value: 'Venezuela', label: 'Venezuela'},
        {value: 'Vietnam', label: 'Vietnam'},
        {value: 'Yemen', label: 'Yemen'},
        {value: 'Zaire', label: 'Zaire'},
        {value: 'Zambia', label: 'Zambia'},
        {value: 'Zimbabwe', label: 'Zimbabwe'},
      ]}
    />
  );
};

export {CountrySelect};
