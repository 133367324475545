import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {selectAppReady} from '../app/store';
import {selectAllLairFilesStringified, selectFilePropById} from '../features/files/FilesSlice';
import {setLairView, selectLairView} from '../features/lairs/LairsSlice';
import {selectSelectedWorkspaceId} from '../app/WorkspacesSlice';

// hook to select lair depending on current state
export default function useLairs({
  ifNoneSelected,
  forcedSelection,
}) {
  const workspaceSelected = useSelector(selectSelectedWorkspaceId);
  const workspaceFileStatus = useSelector(state => selectFilePropById(state, workspaceSelected, 'status_children_fetched'));
  const appReady = useSelector(selectAppReady);
  const lairView = useSelector(selectLairView);
  const dispatch = useDispatch();
  let lairs = useSelector(selectAllLairFilesStringified);
  lairs = JSON.parse(lairs);

  const getForcedSelection = (forcedSelection) => {
    if (forcedSelection) {
      const {propName, value} = forcedSelection;
      const match = lairs.filter(w => w[propName] === value)[0];
      if (match) return match.id;
    }
    return null;
  };

  useEffect(() => {
    if (!appReady || !forcedSelection) return;
    if (workspaceFileStatus === 'idle') {
      const forcedSelectionCheck = getForcedSelection(forcedSelection);
      if (!forcedSelectionCheck) {
        if (ifNoneSelected) ifNoneSelected();
      } else {
        const selection = lairs.filter(w => w.id === forcedSelectionCheck)[0];
        if (selection) {
          if (lairView !== selection.id) dispatch(setLairView(selection.id));
        } else {
          if (ifNoneSelected) ifNoneSelected();
        }
      }
    }
  }, [workspaceFileStatus, appReady]);
}

useLairs.propTypes = {
  ifNoneSelected: PropTypes.func,
  forcedSelection: PropTypes.func,
};
