import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadUsersWorkspaces, resetWorkspaces, setDirectory} from '../app/WorkspacesSlice';
import {
  markFileDirtyByPath,
} from '../features/files/FilesSlice';
import {isElectron, throttle} from '../utils/helpers';
import {selectAppReady} from '../app/store';

const throttleLoadWorkspaces = throttle((dispatch) => {
  dispatch(loadUsersWorkspaces());
}, 1000);

const useFilesChanged = () => {
  const dispatch = useDispatch();
  const homeFolderName = useSelector(state => {
    let folderName = state.workspaces.directory;
    if (!folderName || folderName === 'init') return null;
    folderName = folderName.split('/');
    folderName = folderName[folderName.length - 1];
    return folderName;
  });
  const appReady = useSelector(selectAppReady);

  useEffect(() => {
    if (!appReady) return;
    const handleFilesChanged = async(event, data) => {
      if (!homeFolderName) return;
      const {type, eventType, filePath} = data;
      if (type === 'FILES_CHANGED') {
        // check if change is to homeDirectory since we watch the it's parent
        if (filePath === homeFolderName || filePath.indexOf(`${homeFolderName}/`) === 0) {
          if (eventType === 'change') {
            const relPath = filePath.split('/').slice(1).join('/');
            dispatch(markFileDirtyByPath(relPath));
          } else if (eventType === 'rename') {
            if (filePath === homeFolderName) {
              dispatch(resetWorkspaces());
              dispatch(setDirectory('init'));
              // home folder renamed
            } else {
              throttleLoadWorkspaces(dispatch);
            }
          }
        }
      }
    };

    let removeListener;
    if (isElectron) {
      removeListener = window.electron.message.on('message', handleFilesChanged);
    }
    return () => {
      if (isElectron) {
        removeListener();
      }
    };
  }, [homeFolderName, appReady]);
};

export default useFilesChanged;
