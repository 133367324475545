import React, {useEffect, useState, useRef} from 'react';
import Menu from './Menu';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional - commented this out because its causing jest to fail
import {Flex} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ConditionalWrapper from './ConditionalWrapper';

const ToolTip = styled(Tippy)`
  background-color: transparent !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 24px;
  .tippy-content { padding: 0; }
  .tippy-arrow { color: transparent }
`;

const DropdownMenu = ({
  menuId,
  settings,
  colorMode,
  children,
  event = 'click',
  style = {},
  dropdownStyle = {},
  optionsStyle = {},
  placement = 'bottom',
  iconSide,
  useDispatch,
  menuChildren,
  alwaysVisible = false,
  appendTo = 'parent',
  disableContextMenu,
}) => {
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(alwaysVisible);
  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disableContextMenu) setShowMenu(true);
  };
  useEffect(() => {
    if (ref.current && (settings || menuChildren)) {
      ref.current.addEventListener(event, handleContextMenu);
    }
    return () => {
      if (ref.current && (settings || menuChildren)) {
        ref.current.removeEventListener(event, handleContextMenu);
      }
    };
  });

  return (
    <>
      <ConditionalWrapper
        condition={showMenu}
        wrapper={children => (
          <ToolTip
            theme="menu"
            placement={placement}
            visible={alwaysVisible || showMenu}
            onClickOutside={() => !alwaysVisible && setShowMenu(false)}
            interactive={true}
            appendTo={appendTo}
            content={(settings || menuChildren
              ? <Menu
                id={menuId}
                settings={settings}
                colorMode={colorMode}
                close={() => setShowMenu(false)}
                optionsStyle={optionsStyle}
                iconSide={iconSide}
                useDispatch={useDispatch}
                style={dropdownStyle}
                menuChildren={menuChildren}
              />
              : null
            )}
          >
            {children}
          </ToolTip>
        )}
      >
        {<Flex
          ref={ref}
          style={style}
        >
          {children}
        </Flex>}
      </ConditionalWrapper>
    </>
  );
};

DropdownMenu.propTypes = {
  colorMode: PropTypes.string,
  appendTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  settings: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        display: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        route: PropTypes.string,
        icon: PropTypes.string,
        actionIcon: PropTypes.node,
        hotkey: PropTypes.string,
      })),
    })),
  }),
  children: PropTypes.node,
  alwaysVisible: PropTypes.bool,
  menuChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default DropdownMenu;
