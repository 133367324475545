import {useRouter} from 'next/router';
import {useDispatch, useSelector} from 'react-redux';
import goTo from '../utils/routing';
import useDarkMode from './useDarkMode';
import useDirectory from './useDirectory';
import useUser from './useUser';
import useRollbar from '../lib/useRollbar';
import useWorkspaces from './useWorkspaces';
import useBridge from './UseBridge';
import {isElectron} from '../utils/helpers';
import {selectAppReady} from '../app/store';
import {
  fetchUsersWorkspaces,
  loadUsersWorkspaces,
} from '../app/WorkspacesSlice';
import useLairs from './useLairs';

const goToSetup = () => {
  goTo('/setup/');
};

const goToWorkspace = (name) => {
  goTo(`/workspaces/${name}/lairs/`);
};

export const useApp = ({workspaceName, lairName, isPublic}) => {
  useDarkMode();
  const dispatch = useDispatch();
  const router = useRouter();
  const {accessToken} = router.query;
  const appReady = useSelector(selectAppReady);
  const isLoggedIn = useUser({
    accessToken,
    redirectTo: !isPublic && typeof window !== 'undefined' && appReady && !accessToken && !window.location.href.includes('accessToken') && '/auth/login',
  });
  const directory = useDirectory({
    ifInit: goToSetup,
  });
  const userId = useSelector(state => state.user.id);
  useRollbar();
  useWorkspaces({
    directory,
    isLoggedIn,
    ifStatusSelect: goToSetup,
    ifNoneSelected: goToSetup,
    ifStatusInit: () => {
      if (isElectron) {
        if (!directory) return;
        if (directory !== 'init') dispatch(loadUsersWorkspaces());
      } else {
        if (userId) dispatch(fetchUsersWorkspaces(userId));
      }
    },
    forcedSelection: workspaceName && {
      propName: 'name',
      value: workspaceName,
    },
  });
  useLairs({
    ifNoneSelected: () => {
      if (workspaceName) goToWorkspace(workspaceName);
    },
    forcedSelection: lairName && {
      propName: 'name',
      value: lairName,
    },
  });
  useBridge();

  const isAppReadyAndIsLoggedIn = !appReady || typeof window === 'undefined' ? false : isLoggedIn;

  return isAppReadyAndIsLoggedIn;
};
