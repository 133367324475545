import React from 'react';
import {useViewport} from '../utils/ViewportProvider';
import {bodyFontSize} from '../theme';
import AppBody from './AppBody';
import Toolbar from './AppToolbar';
import {
  Box,
  Flex,
} from '@chakra-ui/react';
import LoadingLogo from './LoadingLogo';
import ExplorerHeader from './ExplorerHeader';

const AppLoading = () => {
  let {width, height} = useViewport();
  width /= bodyFontSize;
  height /= bodyFontSize;
  return (
    <AppBody
      width={width}
      height={height}
      left={
        <Box
          w='100%'
          h='100%'
        >
          <Flex h='100%' w='100%'>
            <Toolbar disabled={true}/>
            <Flex
              direction='column'
              h='100%'
              w='100%'
            >
              <Flex><ExplorerHeader hideToggle={true}/></Flex>
            </Flex>
          </Flex>
        </Box>
      }
      main={<Flex
        height="100%"
        align="center"
        justify="center"
      >
        <LoadingLogo />
      </Flex>}
    />
  );
};

export default AppLoading;
