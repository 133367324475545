import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Input,
  Spinner,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import React, {useEffect} from 'react';

export const GeneralInput = ({
  defaultValue,
  onBlur = () => {},
  handleChange = () => {},
  inputRef,
  placeholder,
  disabled,
  readOnly,
  onKeyDown = () => {},
  dataCy,
  hasButton = false,
  dataTestId,
  loading,
  color,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  return (
    <>
      <Input
        ref={inputRef}
        data-testid={dataTestId || 'input'}
        data-cy={dataCy || 'general-input'}
        w={'100%'}
        focusBorderColor={colors[colorMode].bg[100]}
        backgroundColor={colors[colorMode].bg[800]}
        borderLeft={`1px solid ${colors[colorMode].bg[200]}`}
        borderTop={`1px solid ${colors[colorMode].bg[200]}`}
        borderBottom={`1px solid ${colors[colorMode].bg[200]}`}
        borderRight={hasButton ? 'none' : `1px solid ${colors[colorMode].bg[200]}`}
        color={color}
        px={3}
        py="1rem"
        h='1.8rem'
        borderRadius={hasButton ? '.38rem 0 0 .38rem' : '.38rem'}
        onBlur={(e) => onBlur(e)}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        fontSize=".9rem"
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder} />
      {loading &&
        <Spinner color={`${colorMode}.grey.100`} position='absolute' top='0.6rem' left='.75rem' margin='auto' marginBottom='0' height='1rem' width='1rem' />
      }
    </>
  );
};

export const FileInput = ({
  inputRef,
  error,
  setError = () => {},
  indent = '',
  onBlur,
  onChange = () => {},
  onKeyDown,
  onKeyPress,
  defaultValue = '',
  icon = false,
  errorTime,
  selected,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();

  const onChangeInternal = e => {
    if (error) {
      setError('');
    }
  };

  useEffect(() => {
    const removeError = () => {
      setError('');
    };

    if (error && errorTime) {
      setTimeout(() => {
        removeError();
      }, errorTime);
      window.addEventListener('click', removeError);
    }

    return () => {
      if (error && errorTime) {
        window.removeEventListener('click', removeError);
      }
    };
  }, [error]);

  return (
    <Flex
      pos='relative'
      align="center"
      my={indent === 'search' && '.25rem'}
      px={indent === 'search' && '.5rem'}
      left={indent !== 'search' ? `${2.5 + Number(indent * 1.5)}rem` : '0'}
      w={indent !== 'search' ? `calc(100% - ${3 + Number(indent * 1.5)}rem)` : 'calc(100% - .5rem'}
    >
      {icon && icon}
      <Input
        ref={inputRef}
        data-testid='add-file-input'
        data-cy='add-file-input'
        borderColor={!error ? colors.warning[500] : colors[colorMode].primary[500]}
        w={'100%'}
        focusBorderColor={error ? colors.warning[500] : selected ? colors[colorMode].grey[400] : colors[colorMode].primary[500]}
        px={2}
        ml={icon && '.5rem'}
        h='22px'
        borderRadius='0'
        onBlur={(e) => onBlur(e)}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onChange={e => { onChange(e); onChangeInternal(e); }}
        defaultValue={defaultValue} />
      {error &&
        <Box
          pos='absolute'
          px={3}
          top='1.5rem'
          background={colors.warning[500]}
          color={colors[colorMode].grey[900]}
          borderRadius='3px'
          zIndex="10"
          data-testid="input-error"
        >
          {error}
        </Box>
      }
    </Flex>
  );
};

FileInput.propTypes = {
  inputRef: PropTypes.object,
  error: PropTypes.string,
  indent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  defaultValue: PropTypes.string,
};
