import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ModalConfirmPure from './ModalConfirmPure';
import {
  fetchLeaveWorkspace,
  selectSelectedWorkspace,
} from '../../app/WorkspacesSlice';
import {selectUser} from '../../features/user/UserSlice';

const ModalLeaveWorkspace = ({
  close,
  data,
}) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const user = useSelector(selectUser);
  const workspace = useSelector(selectSelectedWorkspace);

  const handleClick = async() => {
    setPending(true);
    setError(false);
    const data = dispatch(fetchLeaveWorkspace({userId: user.id, workspaceId: workspace.id}));
    if (!data.error) {
      setPending(false);
      setError(false);
      close();
    } else {
      setPending(false);
      setError(true);
    }
  };

  return (
    <ModalConfirmPure
      close={close}
      handleConfirm={handleClick}
      error={error}
      pending={pending}
      bodyText='This action cannot be undone.'
      title="Leave Workspace"
      confirmText="Confirm"
    />
  );
};

ModalLeaveWorkspace.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalLeaveWorkspace;
