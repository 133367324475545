import {
  useTheme,
  useColorMode,
  Flex,
  Tooltip,
  Button,
  Box,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import React from 'react';
import {TOOLBAR_WIDTH} from '../app/constants';
import HelpMenu from './HelpMenu';
import HotKeyTooltip from './HotKeyTooltip';

const ToolbarIcon = ({icon, selected}) => {
  const theme = useTheme();
  const {colorMode} = useColorMode();
  icon = typeof icon === 'string'
    ? <Icon name={icon} width="1rem" color={selected ? theme.colors[colorMode].white[500] : theme.colors[colorMode].grey[100]}/>
    : icon;
  return <Box width="1rem">{icon}</Box>;
};

ToolbarIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Toolbar = ({
  top = null,
  main = null,
  bottom = null,
  disabled,
}) => {
  const {colorMode} = useColorMode();
  let label;
  if (!top.title) label = undefined;
  else label = <HotKeyTooltip title={top.title} command={top.command} />;
  return (
    <Flex
      className="toolbar"
      direction="column"
      background={`${colorMode}.bg.500`}
      width="3.5rem"
      height="100%"
    >
      {top &&
        <Tooltip
          variant="hotkey"
          placement="right-end"
          label={label}
          shouldWrapChildren={false}
        >
          <Button
            flex={`0 1 ${TOOLBAR_WIDTH}`}
            justify="center"
            align="center"
            h="2.5rem"
            minH="2.5rem"
            borderBottom="1px"
            borderRight="1px"
            borderColor={`${colorMode}.grey.500`}
            background={`${colorMode}.bg.400`}
            onClick={!disabled ? top.onClick && top.onClick : undefined}
            cursor="pointer"
          >
            <ToolbarIcon icon={top.icon} />
          </Button>
        </Tooltip>}
      {main && <Flex
        flex="1 1 auto"
        direction="column"
        borderRight="1px"
        borderColor={`${colorMode}.grey.500`}
      >
        {main.map((item, i) => {
          if (!item) return null;
          const label = item.title
            ? <HotKeyTooltip title={item.title} command={item.command} />
            : undefined;
          return <Flex key={`toolbar-main-${i}`}
            flex="0 0 3.25rem"
            justify="center"
            align="center"
          >
            <Tooltip
              variant="hotkey"
              placement="right-end"
              label={label}
              shouldWrapChildren={false}
            >
              <Button
                justify="center"
                align="center"
                width="2.25rem"
                height="2.25rem"
                background={item.selected ? `${colorMode}.primary.500` : 'transparent'}
                borderRadius=".25rem"
                onClick={!disabled ? item.onClick && item.onClick : undefined}
                cursor="pointer"
                _hover={{
                  background: `${colorMode}.grey.500`,
                }}
                tabIndex="0"
              >
                <ToolbarIcon icon={item.icon} selected={item.selected}/>
              </Button>
            </Tooltip>
          </Flex>;
        })}
      </Flex>}
      {bottom &&
        <Tooltip
          variant="hotkey"
          placement="right-end"
          label={bottom.title}
          shouldWrapChildren={false}
        >
          <Button
            flex="0 1 2.5rem"
            justify="center"
            align="center"
            borderTop="1px"
            borderRight="1px"
            borderColor={`${colorMode}.grey.400`}
            background={`${colorMode}.bg.400`}
            onClick={!disabled ? bottom.onClick && bottom.onClick : undefined}
            cursor="pointer">
            <ToolbarIcon icon={bottom.icon} />
          </Button>
        </Tooltip>}
      <HelpMenu page={'editor'} />
    </Flex>
  );
};

Toolbar.propTypes = {
  top: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
  }),
  main: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    selected: PropTypes.bool,
  })),
  bottom: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
  }),
};

export default Toolbar;
