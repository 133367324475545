import {useState} from 'react';
import FilePure from './FilePure';

import {
  IconTemplate,
} from '../../components/Icons';

const LairTemplate = (props) => {
  const [expanded, setExpanded] = useState(props.expandedDefault);
  const entity = props.entities[props.id];

  return (
    <FilePure
      flex='0 0 auto'
      grow={props.grow}
      id={props.id}
      isRoot={props.isRoot}
      name={entity.name}
      indent={props.indent}
      expandable={entity.is_directory && !entity.is_lair_root}
      icon={entity.icon}
      expanded={expanded}
      setExpanded={setExpanded}
      isDirectory={entity.is_directory}
      isLair={entity.is_lair_root}
      onClick={props.onClick}
      selected={props.selectedTemplate === props.id}
      useFilePathIcon={false}
      defaultIcon={<IconTemplate />}
      disableContextMenu={props.disableContextMenu}
    >
      {entity.children
        ? entity.children.map(child => (
          <LairTemplate
            key={`file-${child}`}
            id={child}
            indent={props.indent + 1}
            entities={props.entities}
            onClick={props.onClick}
            selectedTemplate={props.selectedTemplate}
            expandedDefault={props.expandedDefault}
            disableContextMenu={props.disableContextMenu}
          />
        ))
        : null
      }

    </FilePure>
  );
};

export default LairTemplate;
