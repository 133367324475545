import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  useColorMode,
  FormControl,
  FormHelperText,
  Input,
  Select,
  FormLabel,
} from '@chakra-ui/react';
import {useSelector, useDispatch} from 'react-redux';
import {
  convertIntegrationTypeToDisplay,
  selectIntegrationSettingsView,
} from './IntegrationsSlice';
import 'react-datetime/css/react-datetime.css';

const SettingsInput = ({
  id,
  name,
  defaultValue,
  values,
  displayValues,
  type,
  value,
  placeholder,
  dispatch,
  updateIntegrations,
  updateIntegrationsLocal,
  integrationId,
  view,
}) => {
  const {colorMode} = useColorMode();
  let input;
  const inputRef = useRef(null);
  useEffect(() => {
    if (value && inputRef.current && inputRef.current.value !== value) {
      inputRef.current.value = value;
    }
  }, [value, inputRef.current && inputRef.current.value]);
  switch (type) {
  case 'selector':
    input = <Select
      background={`${colorMode}.bg.700`}
      defaultValue={value || defaultValue}
      onChange={async(e) => {
        updateIntegrations({
          id: integrationId,
          updates: {
            [id]: e.target.value,
          },
        });
      }}
    >
      {values.map((v, i) => {
        return (
          <option key={`opt-${i}`} value={v}>
            {displayValues ? displayValues[i] : v}
          </option>
        );
      })}
    </Select>;
    break;
  default:
    input = <Input
      ref={inputRef}
      defaultValue={value || defaultValue || ''}
      placeholder={placeholder}
      background={`${colorMode}.bg.700`}
      type={type === 'password' ? 'password' : ''}
      onBlur={(e) => {
        updateIntegrations({
          id: integrationId,
          updates: {
            [id]: e.target.value,
          },
        });
      }}
    />;
    break;
  }
  return input;
};

const IntegrationSettings = ({
  view,
  type = 'new_relic',
  setView, // needed for deleting integration
  settings,
  setSettings,
}) => {
  const dispatch = useDispatch();
  const {colorMode} = useColorMode();
  const settingsView = useSelector(state => selectIntegrationSettingsView(state, view, type));
  const updateIntegrations = (data) => {
    const {updates} = data;
    Object.entries(updates).forEach(entry => {
      const [name, value] = entry;
      setSettings({
        ...settings,
        [name]: value,
      });
    });
  };

  return (
    <Flex
      w="100%"
      h="100%"
      direction="column"
    >
      {settingsView && settingsView.map((setting, i) => {
        const inputProps = {
          view: view,
          id: setting.id,
          name: setting.name,
          defaultValue: setting.value,
          type: setting.type,
          placeholder: setting.placeholder,
          values: setting.values,
          displayValues: setting.display_values,
        };
        return (
          <FormControl
            key={`setting-${i}`}
            mb="4"
          >
            <FormLabel>{setting.display_name || convertIntegrationTypeToDisplay(setting.id)}</FormLabel>
            <SettingsInput
              integrationId={view}
              dispatch={dispatch}
              updateIntegrations={updateIntegrations}
              {...inputProps}
            />
            {setting.error && <FormHelperText color={`${colorMode}.red.500`}>{setting.error}</FormHelperText>}
          </FormControl>
        );
      })}
      <Flex direction="column" justify="flex-end" grow="1">
        {/* <Button
          colorScheme="red"
          onClick={(row) => {
            setView(null);
            dispatch(showModal({
              which: 'remove_integration',
              data: view,
            }));
          }}
        >
          Remove
        </Button> */}
      </Flex>
    </Flex>
  );
};

IntegrationSettings.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
};

export default IntegrationSettings;
