import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {removeIntegration} from '../integrations/IntegrationsSlice';
import ModalConfirmPure from './ModalConfirmPure';

const ModalRemoveIntegration = ({
  close,
  data,
}) => {
  const dispatch = useDispatch();

  return (
    <ModalConfirmPure
      close={close}
      handleConfirm={() => {
        dispatch(removeIntegration(data));
        close();
      }}
      onClose={close}
      bodyText={[
        'This action cannot be undone.',
      ]}
      title='Are you sure?'
    />
  );
};

ModalRemoveIntegration.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalRemoveIntegration;
