import React, {useState, useRef} from 'react';
import {
  Button,
  Text,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorMode,
  Modal,
  ModalOverlay,
  Input,
  FormLabel,
  Box,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import {useTheme} from '@emotion/react';
import PropTypes from 'prop-types';

const ModalConfirmPure = ({
  close,
  title,
  typeToConfirm = false,
  textToConfirm = 'confirm',
  bodyText = '',
  confirmText = 'Delete',
  handleConfirm,
  pending,
  error,
  variant = 'red',
  preference,
}) => {
  const {colorMode} = useColorMode();
  const theme = useTheme();
  const [value, setValue] = useState('');
  const initialFocusRef = useRef(null);

  return (
    <Modal
      variant={colorMode}
      isOpen={true}
      onClose={close}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader userSelect="none">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {typeof bodyText === 'string'
            ? <Text userSelect="none">{bodyText}</Text>
            : bodyText.map((text, i) => (
              <Text userSelect="none" key={`text-${i}`}>{text}</Text>
            ))}
          {error && <Text userSelect="none" color='red'>{typeof error === 'string' ? error : 'Error: Something went wrong'}</Text>}
          {typeToConfirm &&
            <Box my={theme.space[4]}>
              <FormLabel userSelect="none">{`Type "${textToConfirm}" to confirm this action`}</FormLabel>
              <Input
                type='text'
                value={value}
                onChange={e => {
                  setValue(e.target.value);
                }}
              />
            </Box>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            w="30%"
            variant="white-outline"
            onClick={() => close()}
          >
              Cancel
          </Button>
          <Button
            data-testid='delete-file-confirm-btn'
            data-cy='modal-confirm-btn'
            ml={3}
            w="30%"
            variant={variant}
            onClick={handleConfirm}
            disabled={typeToConfirm && value !== textToConfirm}
            ref={initialFocusRef}
            isLoading={pending}
          >
            <Text>{confirmText}</Text>
          </Button>
        </ModalFooter>
        {preference && <Flex w='100%' px="5" pb="2" justify="right">
          <Checkbox onChange={preference}>{"Don't ask me again"}</Checkbox>
        </Flex>}
      </ModalContent>
    </Modal>
  );
};

ModalConfirmPure.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default ModalConfirmPure;
