import {useDispatch, useSelector} from 'react-redux';
import {
  Flex,
  Text,
  useTheme,
  useColorMode,
} from '@chakra-ui/react';
import {IconHelp} from './Icons';
import DropdownMenu from './DropdownMenu';
import {setHotkeysHelp} from '../features/hotkeys/HotKeysSlice';

const HelpMenu = ({page}) => {
  const {colors} = useTheme();
  const {colorMode} = useColorMode();
  const dispatch = useDispatch();
  const showHotkeys = useSelector(state => state.hotkeys.help);

  return (
    <DropdownMenu
      appendTo={typeof window !== 'undefined' ? document.body : undefined}
      style={{width: '100%'}}
      settings={{
        items: [
          {
            items: [
              {
                display: 'Show shortcuts',
                // eslint-disable-next-line no-return-assign
                onClick: () => { dispatch(setHotkeysHelp(!showHotkeys)); },
                hotkey: '?',
              },
              {
                display: 'Docs',
                onClick: () => window.open('https://wsxdocs.wayscript.com/'),
                hotkey: 'shift+d',
              },
              {
                display: 'Join our Discord',
                onClick: () => window.open('https://discord.gg/SEAPQnCTpp'),
              },
              {
                display: 'Contact Support',
                // eslint-disable-next-line no-return-assign
                onClick: () => location.href = 'mailto:nihar@wayscript.com',
                hotkey: 'shift+s',
              },
            ],
          },
        ],
      }}>
      <Flex px={3} w="100%" borderRight={`1px solid ${colors[colorMode].grey[500]}`}>
        <Flex
          p={page === 'home' && '3.5'}
          align="center"
          cursor="pointer"
          w='100%'
          justify={page === 'editor' && 'center'}
          userSelect="none"
          height={page === 'editor' && '2.25rem'}
          _hover={{backgroundColor: colors[colorMode].grey[600]}}
          borderRadius={'0.5rem'}>
          <IconHelp height={16} width={16} color={colors[colorMode].grey[200]} />
          {page === 'home' && <Text pl={2} color={colors[colorMode].grey[200]}>Help and Support</Text>}
        </Flex>
      </Flex>
    </DropdownMenu>
  );
};

export default HelpMenu;
