import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import {Box} from '@chakra-ui/react';
import ModalView from '../features/modal/ModalView';

const AppLayout = ({isAppReadyAndIsLoggedIn, children}) => {
  return <>
    <Head>
      <title>WayScript</title>
    </Head>
    <ModalView />
    <Box
      className="container app-layout"
      width='100%'
      height='100vh'
    >{children}</Box>
  </>;
};

export default AppLayout;

AppLayout.propTypes = {
  children: PropTypes.node,
};
