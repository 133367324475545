import React, {useState, useRef} from 'react';
import {
  ChakraProvider,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';
import theme from '../theme';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Tippy from '@tippyjs/react';
import {v4} from 'uuid';
import HotKeysCommand from './HotKeysCommand';

const Menu = ({
  id,
  colorMode = 'dark',
  settings,
  close,
  optionsStyle,
  iconSide = 'left',
  error,
  maxHeight,
  style = {},
  useDispatch = () => () => {},
  menuChildren,
}) => {
  const {title, items, footer} = settings || {};
  const [showMenu, setShowMenu] = useState(null);
  const dispatch = useDispatch();
  const menuId = useRef(v4());
  return (
    <ChakraProvider resetCSS theme={theme}
      key={`menu-${menuId.current}`}
      options={{
        useSystemColorMode: true,
      }}
    >
      <Flex
        id={id}
        className="menu-container"
        py={3}
        direction="column"
        background={`${colorMode}.bg.400`}
        minWidth="10rem"
        borderRadius=".4rem"
        maxHeight={maxHeight}
        style={style}
        overflow={'auto'}
        data-cy='menu-container'
      >
        {title && <Text pl="3" fontSize="xl">{title}</Text>}
        {items && items.map((item, i) => {
          return (<React.Fragment key={`fragment-${i}`}>
            <Flex
              key={i}
              direction="column"
              data-testid={`menu-item-${i}`}
            >
              {item.title && <Text pb={3} fontSize="sm" color={`${colorMode}.grey.200`} px={3} userSelect="none">{item.title}</Text>}
              {item.items.map((option, o) => {
                const {icon, actionIcon, hotkey} = option;
                const Content = () => <Flex align="center" w="100%" pl={3} ey={`content-${o}`} key={`option-${o}`}>
                  {icon && <Icon
                    key="icon"
                    name={icon}
                    width={theme.fontSizes[item.iconSize ? item.iconSize : 'lg']}
                    height={theme.fontSizes[item.iconSize ? item.iconSize : 'lg']}
                    color={option.disabled ? `${theme.colors[colorMode].grey[300]}` : icon === 'prod'
                      ? `${theme.colors[colorMode].secondary[500]}`
                      : (icon === 'lair' || icon === 'dev' ? `${theme.colors[colorMode].primary[500]}` : undefined)
                    }
                  />}
                  <Flex
                    key={`text-${o}`}
                    ml={icon && 3}
                    fontSize={item.fontSize || 'md'}
                    color={option.disabled
                      ? `${colorMode}.grey.300`
                      : (option.color ? option.color : 'white')
                    }
                    py=".2rem"
                    pl={icon && 1}
                    userSelect="none"
                    position="relative"
                    flexGrow={1}
                    justify="space-between"
                  >
                    {option.type === 'upload'
                      ? <>
                        {option.display}
                        <input type='file'
                          id='file-header-uploader'
                          name='file-header-uploader'
                          data-cy='file-header-uploader'
                          style={ {
                            opacity: 0,
                            position: 'absolute',
                            height: '2rem',
                            width: '100%',
                            cursor: 'pointer',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 10,
                          } }
                          onChange={async(e) => {
                            e.persist();
                            await option.onClick(e);
                            close();
                          }}
                        />
                      </>
                      : option.display
                    }
                  </Flex>
                </Flex>;
                const onClick = option.onClick && !option.disabled && option.type !== 'upload'
                  ? (e) => {
                    e.stopPropagation();
                    if (!option.useDispatch) {
                      option.onClick(e);
                    } else {
                      dispatch(option.onClick(e));
                    }
                    close && !option.disableClose && close();
                  }
                  : null;

                return (
                  <Tippy
                    key={o}
                    theme="menu"
                    placement={'bottom'}
                    /* eslint-disable-next-line eqeqeq */
                    visible={showMenu == o && option.tooltip}
                    content={option.tooltip}
                  >
                    <Flex
                      key={o}
                      style={optionsStyle}
                      data-testid='menu-option-click'
                      data-cy={option.dataCy || 'menu-option'}
                      py={2}
                      px={3}
                      background={'transparent'}
                      cursor={option.disabled ? 'default' : 'pointer'}
                      justifyContent={iconSide === 'right' ? 'space-between' : 'flex-start'}
                      align="center"
                      onMouseEnter={e => option.type !== 'upload' && setShowMenu(o)}
                      onMouseLeave={e => option.type !== 'upload' && setShowMenu(null)}
                      onClick={onClick}
                      _hover={ option.disabled
                        ? null
                        : {
                          background: `${colorMode}.bg.300`,
                        }}
                    >
                      {option.link
                        ? <Link
                          className={option.link.className}
                          onClick={e => {
                            e.stopPropagation();
                            if (onClick) onClick(e);
                          }}
                          href={option.link.href ? option.link.href : undefined}>
                          <Content />
                        </Link>
                        : <Content />
                      }
                      {actionIcon &&
                        <>
                          {actionIcon}
                        </>
                      }
                      {hotkey && <HotKeysCommand ml={'4'} command={hotkey} context="menu" />}
                    </Flex>
                  </Tippy>
                );
              })}
            </Flex>
            {i < items.length - 1 &&
            <Flex
              key={`separator-${i}`}
              width="100%"
              height=".08125rem"
              background={`${colorMode}.grey.500`}
            >
            </Flex>
            }
          </React.Fragment>);
        })
        }
        {error &&
          <Text pt={3} color={theme.colors.error[500]}>{error}</Text>
        }
        {footer && footer}
        {menuChildren && menuChildren({close})}
      </Flex>
    </ChakraProvider>
  );
};

Menu.propTypes = {
  colorMode: PropTypes.string,
  settings: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        display: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        route: PropTypes.string,
        icon: PropTypes.string,
        actionIcon: PropTypes.node,
        hotkey: PropTypes.string,
      })),
    })),
  }),
  error: PropTypes.string,
  menuChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default Menu;
