import PropTypes from 'prop-types';
import {
  Flex,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import HotKeysCommand from './HotKeysCommand';
const HotKeyTooltip = ({title, command}) => {
  const {colorMode} = useColorMode();
  return (
    <Flex align="center" my="1">
      <Text>{title}</Text>
      <Text ml={'3'} mr={'2'} color={`${colorMode}.grey.400`}>•</Text>
      <HotKeysCommand command={command} context="tooltip" />
    </Flex>
  );
};

HotKeyTooltip.propTypes = {
  title: PropTypes.string,
  command: PropTypes.string,
};

export default HotKeyTooltip;
